import React, { useState } from 'react';
import TourReg from "../../components/TourReg/TourReg";
import styles from './TournamentsConsole.module.css';
import TourCur from "../../components/TourCur/TourCur";
import TourArch from "../../components/TourArch/TourArch";
import EloRanking from "../../components/EloRanking/EloRanking";

const TournamentsConsole = () => {
    const [activeTab, setActiveTab] = useState('registration');

    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <button className={activeTab === 'registration' ? styles.activeTab : ''}
                        onClick={() => setActiveTab('registration')}>Planowane
                </button>
                <button className={activeTab === 'current' ? styles.activeTab : ''}
                        onClick={() => setActiveTab('current')}>Trwające
                </button>
                <button className={activeTab === 'archived' ? styles.activeTab : ''}
                        onClick={() => setActiveTab('archived')}>Zakończone
                </button>
                <button className={activeTab === 'elo' ? styles.activeTab : ''}
                        onClick={() => setActiveTab('elo')}>ELO
                </button>
            </div>


            {activeTab === 'registration' && <TourReg/>}
            {activeTab === 'current' && <TourCur/>}
            {activeTab === 'archived' && <TourArch/>}
            {activeTab === 'elo' && <EloRanking />}
        </div>
    );
};

export default TournamentsConsole;