import React, {useState, useEffect, useContext} from "react";
import {Link} from 'react-router-dom';
import ConfigContext from "../../contexts/ConfigContext";
import {differenceInMonths, endOfMonth, startOfDay, subMonths} from 'date-fns';
import {getUserData, fetchUnpaidReservations, initiatePaymentOnServer, fetchUnpaidFees} from "../../api/api";
import {formatTime, formatDate, formatFullDate} from "../../utils";
import Przelewy24Logo from '../../Przelewy24_logo.svg';
import {FaThumbsUp} from "react-icons/fa";
import styles from "./MyPayments.module.css";
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const MyPayments = () => {
    const [userStatus, setUserStatus] = useState({isMember: null, payedToDate: null});
    const [unpaidReservations, setUnpaidReservations] = useState([]);
    const [unpaidLessonsReservationsPreviousMonth, setUnpaidLessonsReservationsPreviousMonth] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [periodLength, setPeriodLength] = useState(7);
    const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
    const [unpaidFees, setUnpaidFees] = useState([]);


    const {config} = useContext(ConfigContext);

    useEffect(() => {
        let isMounted = true;

        const fetchUserData = async () => {
            try {
                const data = await getUserData();
                if (isMounted) {
                    setUserStatus({isMember: data.member, payedToDate: data.payedToDate});
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Error while fetching user data:", error);
                setError("Błąd pobrania danych.")
            }
        };

        const fetchReservations = async () => {
            try {
                const fetchedReservations = await fetchUnpaidReservations();
                if (isMounted) {
                    setUnpaidReservations(fetchedReservations);
                    setIsLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    setError("Błąd pobrania danych.");
                    setIsLoading(false);
                }
                console.error("Error while fetching reservations:", error);
            }
        };

        const fetchFees = async () => {
            try {
                const fetchedUnpaidFees = await fetchUnpaidFees();
                if (isMounted) {
                    setUnpaidFees(fetchedUnpaidFees);
                    setIsLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    setError("Błąd pobrania danych.");
                    setIsLoading(false);
                }
                console.error("Error while fetching fees:", error);
            }
        };

        fetchUserData();
        fetchReservations();
        fetchFees();

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        const today = new Date();
        const lastDayOfPreviousMonth = endOfMonth(subMonths(today, 1));

        const filteredReservations = unpaidReservations.filter(reservation => {
            const startTime = new Date(reservation.startTime);
            return reservation.status === "LESSONS" && startOfDay(startTime) <= startOfDay(lastDayOfPreviousMonth);
        });

        setUnpaidLessonsReservationsPreviousMonth(filteredReservations);
    }, [unpaidReservations]);


    if (isLoading || isPaymentInProgress) return <Spinner size={"large"}/>;
    if (error) return <ErrorMessage error={error}/>;

    const initiatePayment = async (paymentType, details) => {
        if (isPaymentInProgress) {
            return;
        }

        setIsPaymentInProgress(true);

        try {
            let paymentDetails;
            let amount;

            switch (paymentType) {
                case "reservation":
                    const validReservations = details.reservations.filter(reservation => !reservation.payingInProgress);
                    amount = validReservations.reduce((sum, reservation) => sum + reservation.cost, 0); // Obliczanie kwoty
                    paymentDetails = {
                        reservationIds: validReservations.map(reservation => reservation.id),
                    };
                    break;
                case "periodic":
                    paymentDetails = {
                        days: periodLength,
                        amount: selectedAmount
                    };
                    break;
                case "tournament":
                    const validFees = details.fees || [];
                    amount = validFees.reduce((sum, fee) => sum + fee.amount, 0);
                    paymentDetails = {
                        paymentIds: validFees.map(fee => fee.id),
                    };
                    break;
                default:
                    throw new Error(`Nieznany typ płatności: ${paymentType}`);
            }

            const payment = {
                paymentType: paymentType,
                amount: amount,
                paymentDetails: paymentDetails
            };

            const paymentUrl = await initiatePaymentOnServer(payment);
            if (paymentUrl) {
                window.location.href = paymentUrl; // Przekierowuje użytkownika do URL płatności
            }
        } catch (error) {
            console.error("Błąd podczas inicjowania płatności:", error);
            setError("Błąd podczas inicjowania płatności")
        }
    };


    const totalUnpaid = (unpaidLessonsReservationsPreviousMonth.length > 0 ? unpaidLessonsReservationsPreviousMonth : unpaidReservations)
        .reduce((sum, reservation) => {
            if (reservation.payingInProgress) {
                return sum;
            }
            return sum + reservation.cost;
        }, 0);

    const totalUnpaidFees = unpaidFees.reduce((sum, fee) => sum + fee.amount, 0);


    const unpaidAndNotInProgressCount = unpaidReservations.filter(reservation => !reservation.payingInProgress).length;


    const getSelectedAmount = (period) => {
        switch (period) {
            case 7:
                return config.weeklyFee7Days;
            case 14:
                return config.weeklyFee14Days;
            case 30:
                return config.monthlyFee30Days;
            default:
                return 0;
        }
    };

    const handleSliderChange = (e) => {
        const sliderValue = parseInt(e.target.value, 10);
        let days = 7;
        if (sliderValue === 1) days = 14;
        if (sliderValue === 2) days = 30;
        setPeriodLength(days);
    };

    const selectedAmount = getSelectedAmount(periodLength);

    const initiatePeriodicPayment = () => {
        initiatePayment("periodic", {amount: selectedAmount})
            .then(() => {
                console.log("Płatność okresowa została zainicjowana");
            })
            .catch(error => {
                console.error("Wystąpił błąd podczas inicjowania płatności okresowej:", error);
                setError("Błąd podczas inicjowania płatności")
            });
    };

    const periodLengthToSliderValue = (periodLength) => {
        switch (periodLength) {
            case 7:
                return 0;
            case 14:
                return 1;
            case 30:
                return 2;
            default:
                return 0;
        }
    };

    const sliderValue = periodLengthToSliderValue(periodLength);

    return (
        <div className={styles.MyPayments}>
            {(userStatus.isMember || (userStatus.payedToDate && !userStatus.isMember && differenceInMonths(new Date(), new Date(userStatus.payedToDate)) <= 6)) &&
                <div>
                    {userStatus.isMember &&
                        <button className={styles.memberInfo}>
                            <FaThumbsUp/> Składka BTT opłacona
                        </button>}
                    {userStatus.payedToDate && !userStatus.isMember && differenceInMonths(new Date(), new Date(userStatus.payedToDate)) <= 6 &&
                        <p className={styles.payedToDate} style={{
                            backgroundColor: (() => {
                                const paidToDate = new Date(userStatus.payedToDate);
                                const today = new Date();
                                // Normalize both dates to the start of the day
                                paidToDate.setHours(0, 0, 0, 0);
                                today.setHours(0, 0, 0, 0);
                                return paidToDate >= today ? 'limegreen' : 'lightgrey';
                            })()
                        }}>
                            Opłata okresowa do {formatFullDate(userStatus.payedToDate)}.
                        </p>
                    }
                </div>
            }

            {unpaidReservations.length > 0 || unpaidFees.length > 0 ? (
                <p className={styles.payedIcons}> Do zapłaty:</p>
            ) : (
                <p className={styles.payedIcons}> Brak zaległych płatności.</p>
            )}

            {unpaidReservations.length > 0 && (
                <div className={styles.tableContainer}>
                    <table className={styles.reservationsTable}>
                        <thead>
                        <tr>
                            <th>Data</th>
                            <th>Id</th>
                            <th>Kort</th>
                            <th>Czas</th>
                            <th>Kwota</th>
                            {
                                unpaidLessonsReservationsPreviousMonth.length === 0 &&
                                <th></th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {(unpaidLessonsReservationsPreviousMonth.length > 0 ?
                            unpaidLessonsReservationsPreviousMonth : unpaidReservations).map(({
                                                                                                  id,
                                                                                                  payingInProgress,
                                                                                                  startTime,
                                                                                                  courtId,
                                                                                                  endTime,
                                                                                                  cost
                                                                                              }) => (
                            <tr key={id}>
                                <td>{formatDate(startTime, true)}</td>
                                <td>{id}</td>
                                <td>{courtId}</td>
                                <td>{`${formatTime(startTime)} - ${formatTime(endTime)}`}</td>
                                <td>{cost.toFixed(2)}</td>
                                {
                                    unpaidLessonsReservationsPreviousMonth.length === 0 &&
                                    <td>
                                        {payingInProgress ? null : (
                                            <button
                                                className={styles.button}
                                                disabled={isPaymentInProgress}
                                                onClick={() =>
                                                    initiatePayment("reservation", {
                                                        reservations: [
                                                            {
                                                                id,
                                                                startTime,
                                                                courtId,
                                                                endTime,
                                                                cost,
                                                            },
                                                        ],
                                                    })
                                                }
                                            >
                                                {isPaymentInProgress ? 'Proszę czekać...' : 'Płacę'}
                                            </button>
                                        )}
                                    </td>
                                }
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {((totalUnpaid > 0 && unpaidAndNotInProgressCount >= 2) && unpaidLessonsReservationsPreviousMonth.length === 0) && (
                        <button
                            className={styles.buttonAll}
                            disabled={isPaymentInProgress || totalUnpaid === 0}
                            onClick={() =>
                                initiatePayment("reservation", {
                                    reservations: unpaidReservations,
                                })
                            }
                        >
                            {isPaymentInProgress ? 'Proszę czekać...' : `Spłacam wszystkie rezerwacje: ${totalUnpaid.toFixed(2)} zł`}
                        </button>

                    )}
                    {unpaidLessonsReservationsPreviousMonth.length > 0 && (
                        <button
                            className={styles.buttonAll}
                            disabled={isPaymentInProgress || totalUnpaid === 0}
                            onClick={() =>
                                initiatePayment("reservation", {
                                    reservations: unpaidLessonsReservationsPreviousMonth,
                                })
                            }
                        >
                            {isPaymentInProgress ? 'Proszę czekać...' : `Płacę za poprzedni miesiąc : ${totalUnpaid.toFixed(2)} zł`}
                        </button>

                    )}
                </div>
            )}
            {unpaidFees.length > 0 && (
                <div className={styles.tableContainer}>
                    <table className={styles.reservationsTable}>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Tytuł</th>
                            <th>Kwota</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {unpaidFees.length > 0 && (
                            unpaidFees.map(({id, title, status, amount}) => (
                                <tr key={id}>
                                    <td>{id}</td>
                                    <td>{title}</td>
                                    <td>{amount.toFixed(2)}</td>
                                    <td>
                                        <button
                                            className={styles.button}
                                            disabled={isPaymentInProgress}
                                            onClick={() =>
                                                initiatePayment("tournament", {
                                                    fees: [
                                                        {
                                                            id,
                                                            title,
                                                            status,
                                                            amount
                                                        },
                                                    ],
                                                })
                                            }
                                        >
                                            {isPaymentInProgress ? 'Proszę czekać...' : 'Płacę'}
                                        </button>
                                    </td>
                                </tr>
                            )))}
                        </tbody>
                    </table>

                    {unpaidFees.length > 1 && (
                        <button
                            className={styles.buttonAll}
                            disabled={isPaymentInProgress || totalUnpaidFees === 0}
                            onClick={() =>
                                initiatePayment("tournament", {
                                    fees: unpaidFees,
                                })
                            }
                        >
                            {isPaymentInProgress ? 'Proszę czekać...' : `Spłacam wszystkie opłaty: ${totalUnpaidFees.toFixed(2)} zł`}
                        </button>
                    )}

                </div>
            )}

            <Link to="/payment-history" className={styles.paymentHistoryButton}>Historia płatności</Link>

            {!userStatus.isMember && unpaidReservations.length === 0 && unpaidFees.length === 0 && config.enableReservation ? (
                <div>
                    <p className={styles.periodicTitle}>Wybieram płatność okresową za następującą ilość dni:</p>
                    <div className={styles.sliderContainer}>
                        <input
                            type="range"
                            min="0"
                            max="2"
                            step="1"
                            value={sliderValue}
                            onChange={handleSliderChange}
                            className={styles.slider}
                        />
                        <div className={styles.sliderLabels}>
                            <span>7 </span>
                            <span>14 </span>
                            <span>30 </span>
                        </div>
                    </div>
                    <button
                        className={`${styles.button} ${styles.periodicPaymentButton}`}
                        disabled={isPaymentInProgress}  // dezaktywuje przycisk podczas inicjacji płatności
                        onClick={() => initiatePeriodicPayment()}
                    >
                        {isPaymentInProgress ? 'Proszę czekać...' : `Płacę ${selectedAmount} zł`}
                    </button>
                </div>
            ) : null}
            {(!(unpaidReservations.length === 0) || !(unpaidFees.length === 0) || !userStatus.isMember) && (
                <div className={styles.paymentInfo}>
                    <p>Płatności realizowane za pośrednictwem:</p>
                    <img src={Przelewy24Logo} alt="Przelewy24"/>
                    {!userStatus.isMember && config.enableReservation && (
                        <Link to="/member-fee" className={styles.memberFeeButton}>Chcę opłacić składkę BTT</Link>
                    )}
                </div>
            )}
        </div>
    );
};
export default MyPayments;