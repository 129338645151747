import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {fetchMatchById, getUserData, updateUserData} from '../../api/api';
import {FaPhone, FaEnvelope, FaFacebookMessenger} from 'react-icons/fa';
import CustomModal from "../../UI/Modal/CustomModal";
import styles from './MyProfile.module.css';
import style from "../TourRegForm/TourRegForm.module.css";
import eloRankingStyles from './../EloRanking/EloRanking.module.css';
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {formatDateDDMMYY, loadEloHistory, renderMatchResult, renderPartnerName, renderPlayerName} from "../../utils";
import {IoArrowUndoOutline} from "react-icons/io5";

const MyProfile = () => {
    const [userData, setUserData] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [updatedData, setUpdatedData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isPositiveMessage, setIsPositiveMessage] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [eloSingleHistory, setEloSingleHistory] = useState([]);
    const [eloDoubleHistory, setEloDoubleHistory] = useState([]);

    const [selectedMatchType, setSelectedMatchType] = useState(null);
    const [matchDetails, setMatchDetails] = useState(null);


    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const data = await getUserData();
                if (isMounted) {
                    setUserData(data);
                    setUpdatedData({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phone: data.phone,
                        messengerContact: data.messengerContact || '',
                        coachLessonInformation: data.coachLessonInformation || '',
                        coachActive: data.coachActive || false,
                        showPhone: data.showPhone || false,
                        showEmail: data.showEmail || false,
                        showMessenger: data.showMessenger || false,
                        receiveNotifications: data.receiveNotifications || false,
                        ntrpRangeMin: data.ntrpRangeMin ? parseFloat(data.ntrpRangeMin).toFixed(1) : '1.0',
                        ntrpRangeMax: data.ntrpRangeMax ? parseFloat(data.ntrpRangeMax).toFixed(1) : '3.0'
                    });

                    const singles = await loadEloHistory(data.id, "SINGLES");
                    const doubles = await loadEloHistory(data.id, "DOUBLES");

                    setEloSingleHistory(singles || []);
                    setEloDoubleHistory(doubles || []);

                    setIsLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    setError("Błąd pobrania danych");
                    setIsLoading(false);
                }
            }
        };

        fetchData();

        // Cleanup function to mark the component as unmounted
        return () => {
            isMounted = false;
        };
    }, []);



    const loadMatchDetails = async (matchId) => {
        setIsLoading(true);
        try {
            const result = await fetchMatchById(matchId); // Funkcja API do pobrania szczegółów meczu
            if (result.success) {
                setMatchDetails(result.data); // Ustaw dane meczu
            }
        } catch (error) {
            console.error("Błąd ładowania szczegółów meczu:", error);
            setError("Nie udało się załadować szczegółów meczu.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setMatchDetails(null); // Zamknij modal
    };


    const handleEloClick = (matchType) => {
        setSelectedMatchType(matchType);
    };

    const handleBackToProfile = () => {
        setSelectedMatchType(null);
        setMatchDetails(null);
    };


    const handleInputChange = (event) => {
        const {name, value, type, checked} = event.target;
        setUpdatedData({
            ...updatedData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const updateUserDTO = {
            ...updatedData,
            id: userData.id,
            messengerContact: updatedData.messengerContact,
            receiveNotifications: updatedData.receiveNotifications,
            ntrpRangeMin: updatedData.ntrpRangeMin,
            ntrpRangeMax: updatedData.ntrpRangeMax
        };

        if (updateUserDTO.showMessenger && updatedData.messengerContact === "") {
            setModalMessage("Jeżeli messenger ma być preferowanym kontaktem, uzupełnij pole 'Messenger'");
            setIsPositiveMessage(false);
            setIsModalOpen(true);
            return;
        }

        if (updateUserDTO.coachActive && !updateUserDTO.showPhone && !updateUserDTO.showEmail && !updateUserDTO.showMessenger) {
            setModalMessage("Dla lekcji tenisa powinien być zaznaczony przynajmniej jeden kontakt.");
            setIsPositiveMessage(false);
            setIsModalOpen(true);
            return;
        }

        updateUserData(updateUserDTO)
            .then(() => {
                setUserData({...userData, ...updateUserDTO});
                setIsEditing(false);
                setIsPositiveMessage(true);
                setIsModalOpen(true);
                setModalMessage("Dane zostały zaaktualizowane.");
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setModalMessage(error.response.data);
                } else {
                    setModalMessage("Wystąpił nieoczekiwany błąd");
                    setError("Błąd aktualizacji danych")
                }
                setIsPositiveMessage(false);
                setIsModalOpen(true);
            });
    };


    const renderEloHistory = () => {
        const history = selectedMatchType === "SINGLES" ? eloSingleHistory : eloDoubleHistory; // Wybierz historię

        return (
            <div className={eloRankingStyles.historyContainer}>
                <button onClick={handleBackToProfile} className={eloRankingStyles.backButton}><IoArrowUndoOutline /></button>
                <h5>Historia Elo {selectedMatchType === "SINGLES" ? "Singiel" : "Debel"}</h5>
                <table className={eloRankingStyles.table}>
                    <thead>
                    <tr>
                        <th>ELO</th>
                        <th>+/-</th>
                        <th>Data</th>
                        <th>Oponenci</th>
                    </tr>
                    </thead>
                    <tbody>
                    {history.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.elo}</td>
                            <td>
                                <a href="#" onClick={() => loadMatchDetails(entry.matchId)}>
                                    {entry.eloChange}
                                </a>
                            </td>
                            <td>{formatDateDDMMYY(entry.date)}</td>
                            <td>
                                {entry.opponent}
                                {entry.opponentPartner && ` / ${entry.opponentPartner}`}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                {matchDetails && (
                    <div className={eloRankingStyles.modal}>
                        <div className={eloRankingStyles.modalContent}>
                            <span>{matchDetails.tournamentTitle}</span>
                            <span className={eloRankingStyles.closeButton} onClick={handleCloseModal}>&times;</span>
                            <div className={eloRankingStyles.result}>
                                <div>
                                    <div className={eloRankingStyles.playerNames}>
                                        <div>
                                            {renderPlayerName(matchDetails.participant1Id, matchDetails.participant1LastName, matchDetails.participant1FirstName, matchDetails)}
                                            {matchDetails.participant1PartnerLastName && (
                                                <>
                                                    <br/>
                                                    <span className={eloRankingStyles.partnerName}>
                                                            {renderPartnerName(matchDetails.participant1Id, matchDetails.participant1PartnerLastName, matchDetails.participant1PartnerFirstName, matchDetails)}
                                                        </span>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            {renderPlayerName(matchDetails.participant2Id, matchDetails.participant2LastName, matchDetails.participant2FirstName, matchDetails)}
                                            {matchDetails.participant2PartnerLastName && (
                                                <>
                                                    <br/>
                                                    <span className={eloRankingStyles.partnerName}>
                                                            {renderPartnerName(matchDetails.participant2Id, matchDetails.participant2PartnerLastName, matchDetails.participant2PartnerFirstName, matchDetails)}
                                                        </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={eloRankingStyles.resultOfMatch}>
                                    {matchDetails.setResults && renderMatchResult(matchDetails.setResults)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };


    // Render form fields for coach-specific data
    const renderCoachFields = () => (
        userData.coach && (
            <>
                <div>
                    <label htmlFor="coachLessonInformation" className={styles.label}>Informacje dla 'Lekcje
                        tenisa'</label>
                    <textarea
                        name="coachLessonInformation"
                        value={updatedData.coachLessonInformation}
                        onChange={handleInputChange}
                        placeholder="Informacja dla chętnych na lekcje tenisa (max 300 znaków)"
                        className={styles.textarea}
                    />
                </div>
                <div className={styles.checkboxContainer}>
                    <label className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            name="coachActive"
                            checked={updatedData.coachActive}
                            onChange={handleInputChange}
                        />
                        <span className={styles.labelText}> Pokaż moją kartę w 'Lekcje tenisa'</span>
                    </label>
                </div>
            </>
        )
    );

    const formatPhoneNumber = (phoneNumber) => {
        // Treat undefined or null phoneNumber as an empty string
        const digits = (phoneNumber || '').replace(/\D/g, '');

        // Format based on length
        if (digits.length === 9) {
            // Format as XXX XXX XXX
            return digits.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
        } else if (digits.length === 11) {
            // Format as XX XXX XXX XXX
            return digits.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
        }

        // Return original format if it doesn't match expected lengths
        return phoneNumber || ''; // Return an empty string if phoneNumber is falsy
    };


    const renderUserDataCard = () => (
        <>
            <div className={styles.userCard}>
                <h5>{`${userData.firstName} ${userData.lastName}`}</h5>
                <div className={styles.contactInfo}>
                    <FaEnvelope className={styles.icon}/>
                    <span>{userData.username}</span>
                </div>
                <div className={styles.contactInfo}>
                    <FaPhone className={styles.icon}/>
                    <span>{formatPhoneNumber(userData.phone)}</span>
                </div>
                 {userData.messengerContact && (
                    <div className={styles.contactInfo}>
                        <FaFacebookMessenger className={styles.icon}/>
                        {userData.messengerContact}
                    </div>
                )}
                <div className={styles.eloContainer}>
                    {eloSingleHistory.length > 0 && (
                        <div>
                            <h5 className={styles.eloName}>
                                elo singiel:
                                <a className={styles.eloValue} href="#" onClick={() => handleEloClick("SINGLES")}>
                                    {userData.eloSingle}
                                </a>
                            </h5>
                        </div>
                    )}
                    {eloDoubleHistory.length > 0 && (
                        <div>
                            <h5 className={styles.eloName}>
                                elo debel:
                                <a className={styles.eloValue} href="#" onClick={() => handleEloClick("DOUBLES")}>
                                    {userData.eloDouble}
                                </a>
                            </h5>
                        </div>
                    )}
                </div>
                {(userData.showEmail || userData.showMessenger || userData.showPhone) && (
                    <>
                        <h5 className={styles.preferContact}>Preferowany kontakt:</h5>
                        <div className={styles.preferContactIcons}>
                            {userData.showEmail &&
                                <FaEnvelope/>}
                            {userData.showPhone && <FaPhone/>}
                            {userData.showMessenger && <FaFacebookMessenger/>}
                        </div>
                    </>

                )
                }
                {userData.receiveNotifications &&
                    (
                        <h5 className={styles.infoPartner}>
                            Email dla
                            NTRP {parseFloat(userData.ntrpRangeMin).toFixed(1)} - {parseFloat(userData.ntrpRangeMax).toFixed(1)}
                        </h5>
                    )
                }
            </div>
            {
                userData.coach && !userData.coachActive &&
                <h5 className={styles.infoCard}>Nie prezentujesz swojej karty informacyjnej w "Lekcje tenisa".</h5>
            }
        </>
    );

    const renderCoachCard = () => (
        userData.coach && userData.coachActive && (
            <>
                <h5 className={styles.infoCard}>Twoja karta informacyjna w "Lekcje tenisa"</h5>
                <div className={styles.coachCard}>
                    <h5>{`${userData.firstName} ${userData.lastName}`}</h5>
                    <br/>
                    <p>{userData.coachLessonInformation}</p>
                    <div className={styles.contactIcons}>
                        {userData.showEmail &&
                            <FaEnvelope/>}
                        {userData.showPhone && <FaPhone/>}
                        {userData.showMessenger && <FaFacebookMessenger/>}
                    </div>
                </div>
            </>
        )
    );

    const renderEditForm = () => (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="firstName" className={styles.label}>Imię</label>
                <input type="text" name="firstName" value={updatedData.firstName} onChange={handleInputChange}
                       className={styles.input}/>
            </div>
            <div>
                <label htmlFor="lastName" className={styles.label}>Nazwisko</label>
                <input type="text" name="lastName" value={updatedData.lastName} onChange={handleInputChange}
                       className={styles.input}/>
            </div>
            <div>
                <label htmlFor="phone" className={styles.label}>Telefon</label>
                <input type="text" name="phone" value={updatedData.phone} onChange={handleInputChange}
                       className={styles.input}/>
            </div>
            <div>
                <label htmlFor="messengerContact" className={styles.label}>Messenger</label>
                <input
                    type="text"
                    name="messengerContact"
                    value={updatedData.messengerContact}
                    onChange={handleInputChange}
                    className={styles.input}
                    placeholder="jan.kowalski.48"
                />
            </div>

            <div className={styles.checkboxContainer}>
                <label className={styles.checkboxLabel}>
                    <input
                        type="checkbox"
                        name="receiveNotifications"
                        checked={updatedData.receiveNotifications}
                        onChange={handleInputChange}
                    />
                    <span className={styles.labelText}>Email 'Szukam partnera do gry'</span>
                </label>
            </div>

            {updatedData.receiveNotifications && (
                <div className={styles.rangeContainer}>
                    <label htmlFor="ntrpRangeMin" className={styles.label}>Zakres NTRP od</label>
                    <select
                        className={styles.rangeSelect}
                        id="ntrpRangeMin"
                        name="ntrpRangeMin"
                        value={updatedData.ntrpRangeMin}
                        onChange={handleInputChange}
                    >
                        <option value="1.0">1.0</option>
                        <option value="1.5">1.5</option>
                        <option value="2.0">2.0</option>
                        <option value="2.5">2.5</option>
                        <option value="3.0">3.0</option>
                        <option value="3.5">3.5</option>
                        <option value="4.0">4.0</option>
                        <option value="4.5">4.5</option>
                        <option value="5.0">5.0</option>
                        <option value="5.5">5.5</option>
                    </select>
                    <label htmlFor="ntrpRangeMax" className={styles.label}>do</label>
                    <select
                        className={styles.rangeSelect}
                        id="ntrpRangeMax"
                        name="ntrpRangeMax"
                        value={updatedData.ntrpRangeMax}
                        onChange={handleInputChange}
                    >
                        <option value="1.0">1.0</option>
                        <option value="1.5">1.5</option>
                        <option value="2.0">2.0</option>
                        <option value="2.5">2.5</option>
                        <option value="3.0">3.0</option>
                        <option value="3.5">3.5</option>
                        <option value="4.0">4.0</option>
                        <option value="4.5">4.5</option>
                        <option value="5.0">5.0</option>
                        <option value="5.5">5.5</option>
                    </select>
                </div>
            )}

            <h5 className={styles.label}>Preferowany kontakt</h5>
            <div className={styles.checkboxContainer}>
                <label className={styles.checkboxLabel}>
                    <input
                        type="checkbox"
                        name="showPhone"
                        checked={updatedData.showPhone}
                        onChange={handleInputChange}
                    />
                    <span className={styles.labelText}>telefon</span>
                </label>
            </div>
            <div className={styles.checkboxContainer}>
                <label className={styles.checkboxLabel}>
                    <input
                        type="checkbox"
                        name="showEmail"
                        checked={updatedData.showEmail}
                        onChange={handleInputChange}
                    />
                    <span className={styles.labelText}>email</span>
                </label>
            </div>
            <div className={styles.checkboxContainer}>
                <label className={styles.checkboxLabel}>
                    <input
                        type="checkbox"
                        name="showMessenger"
                        checked={updatedData.showMessenger}
                        onChange={handleInputChange}
                    />
                    <span className={styles.labelText}>messenger</span>
                </label>
            </div>

            {renderCoachFields()}
            <div className={style.buttonContainer}>
                <button className={styles.button} type="submit">Zapisz</button>
                <button className={styles.button} type="button" onClick={() => setIsEditing(false)}>Anuluj</button>
            </div>
        </form>
    );


    if (isLoading) return <Spinner size={"large"}/>;
    if (error) return <ErrorMessage error={error}/>;


    return (
        <div className={styles.container}>
            {isLoading && <Spinner size="large" />}
            {error && <ErrorMessage error={error} />}

            {!isLoading && !error && (
                selectedMatchType ? (
                    renderEloHistory() // Wyświetlenie historii, jeśli wybrano typ
                ) : (
                    <div className={styles.userDataContainer}>
                        {isEditing ? renderEditForm() : renderUserDataCard()}
                        {!isEditing && renderCoachCard()}
                        {!isEditing && (
                            <div className={style.buttonContainer}>
                                <button className={styles.button} onClick={() => setIsEditing(true)}>
                                    Edytuj dane
                                </button>
                            </div>
                        )}
                    </div>
                )
            )}

            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    setIsModalOpen(false);
                    if (isPositiveMessage) {
                        navigate('/my-profile/profile');
                    }
                }}
                message={modalMessage}
                isPositiveMessage={isPositiveMessage}
                cancelLabel="Zamknij"
            />
        </div>
    );

};

export default MyProfile;
