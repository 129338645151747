import React, {useEffect, useState} from 'react';
import styles from './Podium.module.css';

import podium1Image from './../../assets/images/podium1.png';
import podium2Image from './../../assets/images/podium2.png';
import podium3Image from './../../assets/images/podium3.png';

const Podium = ({standings, matches, competitionSystem}) => {
    const [winners, setWinners] = useState([]);

    const formatName = (firstName, lastName, partnerFirstName, partnerLastName) => {
        return `${lastName} ${firstName[0]}` + (partnerFirstName && partnerLastName ? ` & ${partnerLastName} ${partnerFirstName[0]}` : '');
    };

    const getDirectDuelResult = (participantIdA, participantIdB, matches) => {
        const directDuelMatch = matches.find(match =>
            (match.participant1Id === participantIdA && match.participant2Id === participantIdB) ||
            (match.participant1Id === participantIdB && match.participant2Id === participantIdA)
        );

        if (directDuelMatch) {
            let setsWonByA = 0;
            let setsWonByB = 0;

            directDuelMatch.setResults.forEach(set => {
                if (set.playerOneGames > set.playerTwoGames) {
                    if (directDuelMatch.participant1Id === participantIdA) {
                        setsWonByA++;
                    } else {
                        setsWonByB++;
                    }
                } else if (set.playerTwoGames > set.playerOneGames) {
                    if (directDuelMatch.participant2Id === participantIdA) {
                        setsWonByA++;
                    } else {
                        setsWonByB++;
                    }
                }
            });

            if (setsWonByA > setsWonByB) {
                return -1;
            } else if (setsWonByB > setsWonByA) {
                return 1;
            }
        }

        return 0;
    };

    const sortStandings = () => {
        return [...standings].sort((a, b) => {
            if (a.points !== b.points) {
                return b.points - a.points;
            }
            const gamesDifferenceA = a.gamesWon - a.gamesLost;
            const gamesDifferenceB = b.gamesWon - b.gamesLost;
            if (gamesDifferenceA !== gamesDifferenceB) {
                return gamesDifferenceB - gamesDifferenceA;
            }
            return getDirectDuelResult(a.participantId, b.participantId, matches);
        });
    };

    const updatePodiumWinners = () => {
        if (competitionSystem === 'ROUND_ROBIN') {
            const sortedStandings = sortStandings();
            if (sortedStandings.length >= 3) {
                const firstPlace = sortedStandings[0];
                const secondPlace = sortedStandings[1];
                const thirdPlace = sortedStandings[2];

                setWinners([
                    {
                        name: `${secondPlace.participantName}${secondPlace.participantPartnerName ? ' & ' + secondPlace.participantPartnerName : ''}`,
                        place: 2
                    },
                    {
                        name: `${firstPlace.participantName}${firstPlace.participantPartnerName ? ' & ' + firstPlace.participantPartnerName : ''}`,
                        place: 1
                    },
                    {
                        name: `${thirdPlace.participantName}${thirdPlace.participantPartnerName ? ' & ' + thirdPlace.participantPartnerName : ''}`,
                        place: 3
                    }
                ]);
            }
        } else if (competitionSystem === 'KNOCKOUT' || competitionSystem === 'BRAZILIAN'  || competitionSystem === 'COMBINED') {
            let firstPlaceMatch, thirdPlaceMatch;

            if (matches.length === 16) {
                firstPlaceMatch = findMatchByNumber(15);
                thirdPlaceMatch = findMatchByNumber(16);
            } else if (matches.length === 8) {
                firstPlaceMatch = findMatchByNumber(7);
                thirdPlaceMatch = findMatchByNumber(8);
            } else if (matches.length === 4) {
                firstPlaceMatch = findMatchByNumber(3);
                thirdPlaceMatch = findMatchByNumber(4);
            } else if (matches.length === 14) {
                firstPlaceMatch = findMatchByNumber(13);
                thirdPlaceMatch = findMatchByNumber(14);
            } else if (matches.length === 30) {
                firstPlaceMatch = findMatchByNumber(29);
                thirdPlaceMatch = findMatchByNumber(30);
            }

            if (firstPlaceMatch) {
                const first = formatName(
                    firstPlaceMatch.participantWinnerId === firstPlaceMatch.participant1Id ? firstPlaceMatch.participant1FirstName : firstPlaceMatch.participant2FirstName,
                    firstPlaceMatch.participantWinnerId === firstPlaceMatch.participant1Id ? firstPlaceMatch.participant1LastName : firstPlaceMatch.participant2LastName,
                    firstPlaceMatch.participantWinnerId === firstPlaceMatch.participant1Id ? firstPlaceMatch.participant1PartnerFirstName : firstPlaceMatch.participant2PartnerFirstName,
                    firstPlaceMatch.participantWinnerId === firstPlaceMatch.participant1Id ? firstPlaceMatch.participant1PartnerLastName : firstPlaceMatch.participant2PartnerLastName
                );

                const second = formatName(
                    firstPlaceMatch.participantWinnerId === firstPlaceMatch.participant1Id ? firstPlaceMatch.participant2FirstName : firstPlaceMatch.participant1FirstName,
                    firstPlaceMatch.participantWinnerId === firstPlaceMatch.participant1Id ? firstPlaceMatch.participant2LastName : firstPlaceMatch.participant1LastName,
                    firstPlaceMatch.participantWinnerId === firstPlaceMatch.participant1Id ? firstPlaceMatch.participant2PartnerFirstName : firstPlaceMatch.participant1PartnerFirstName,
                    firstPlaceMatch.participantWinnerId === firstPlaceMatch.participant1Id ? firstPlaceMatch.participant2PartnerLastName : firstPlaceMatch.participant1PartnerLastName
                );

                const third = thirdPlaceMatch
                    ? formatName(
                        thirdPlaceMatch.participantWinnerId === thirdPlaceMatch.participant1Id ? thirdPlaceMatch.participant1FirstName : thirdPlaceMatch.participant2FirstName,
                        thirdPlaceMatch.participantWinnerId === thirdPlaceMatch.participant1Id ? thirdPlaceMatch.participant1LastName : thirdPlaceMatch.participant2LastName,
                        thirdPlaceMatch.participantWinnerId === thirdPlaceMatch.participant1Id ? thirdPlaceMatch.participant1PartnerFirstName : thirdPlaceMatch.participant2PartnerFirstName,
                        thirdPlaceMatch.participantWinnerId === thirdPlaceMatch.participant1Id ? thirdPlaceMatch.participant1PartnerLastName : thirdPlaceMatch.participant2PartnerLastName
                    )
                    : '';

                setWinners([
                    {name: second, place: 2},
                    {name: first, place: 1},
                    {name: third, place: 3}
                ]);
            }
        }
    };

    useEffect(() => {
        updatePodiumWinners();
    }, [standings, matches, competitionSystem]);

    const findMatchByNumber = (number) => {
        return matches.find(match => match.matchNumber === number);
    };

    return (
        <div className={styles.podiumContainer}>
            {winners.map(winner => (
                <div key={winner.place} className={styles.podiumStep}>
                    <img src={winner.place === 1 ? podium1Image : winner.place === 2 ? podium2Image : podium3Image}
                         alt={`Place ${winner.place}`}/>
                    <div className={styles.winnerName}>
                        {winner.name.split('&').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br/>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Podium;
