import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import MyReservations from "../../components/MyReservations/MyReservations";
import MyProfile from "../../components/MyProfile/MyProfile";
import MyPayments from "../../components/MyPayments/MyPayments";
import styles from './ProfileConsole.module.css';

const ProfileConsole = () => {
    const [activeTab, setActiveTab] = useState('reservations');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Set the active tab based on the current path
        const path = location.pathname.split('/').pop(); // This gets the last segment of the URL
        switch (path) {
            case "payments":
                setActiveTab('payments');
                break;
            case "profile":
                setActiveTab('profile');
                break;
            default:
                setActiveTab('reservations'); // Default to 'reservations' for any other sub-path under /my-profile
        }
    }, [location]);


    useEffect(() => {
        // Check authentication and redirect if not authenticated
        const isAuthenticated = () => {
            const token = localStorage.getItem('token');
            if (!token) {
                return false;
            }
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            const decodedToken = JSON.parse(window.atob(base64));
            const expirationDate = new Date(decodedToken.exp * 1000);
            return expirationDate > new Date();
        };

        if (!isAuthenticated()) {
            navigate('/login');
        }
    }, [navigate]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        // Navigate to the new route when a tab changes
        navigate(`/my-profile/${tab}`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <button className={activeTab === 'reservations' ? styles.activeTab : ''}
                        onClick={() => handleTabChange('reservations')}>Rezerwacje
                </button>
                <button className={activeTab === 'profile' ? styles.activeTab : ''}
                        onClick={() => handleTabChange('profile')}>Profil
                </button>
                <button className={activeTab === 'payments' ? styles.activeTab : ''}
                        onClick={() => handleTabChange('payments')}>Płatności
                </button>
            </div>

            {activeTab === 'reservations' && <MyReservations/>}
            {activeTab === 'profile' && <MyProfile/>}
            {activeTab === 'payments' && <MyPayments/>}
        </div>
    );
};

export default ProfileConsole;
