import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import config from "../../config/config";
import CustomModal from "../Modal/CustomModal";


const TokenConfirmation = () => {
    const { tokenType, token } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isPositiveMessage, setIsPositiveMessage] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        let apiUrl;

        if (tokenType === "register") {
            apiUrl = `${config.apiUrl}/register/confirm?token=${token}`;
        } else if (tokenType === "reset-password") {
            navigate(`/reset-password/reset-form/${token}`);
        } else {
            return;
        }

        axios
            .get(apiUrl)
            .then((response) => {
                setMessage(response.data.message);
                setIsPositiveMessage(response.data.isPositiveMessage);
                setIsOpen(true);
            })
            .catch((error) => {
                console.error("Error:", error);
                if (error.response) {
                    setMessage(error.response.data);
                    setIsPositiveMessage(false);
                    setIsOpen(true);
                } else {
                    setMessage("Wystąpił nieoczekiwany błąd.");
                    setIsPositiveMessage(false);
                    setIsOpen(true);
                }
            });
    }, [token, tokenType]);

    return (
        <CustomModal
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
                navigate("/login");
            }}
            message={message}
            isPositiveMessage={isPositiveMessage}
            cancelLabel="Zamknij"
        />
    );
};

export default TokenConfirmation;