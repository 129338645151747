import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from './ReservationCancelModal.module.css'

const ReservationCancelModal = ({
                                    isOpen,
                                    onRequestClose,
                                    message,
                                    isPositiveMessage,
                                    confirmLabel,
                                    onConfirm,
                                    confirmButtonColor,
                                    cancelLabel,
                                    onCancel,
                                    cancelButtonColor,
                                    showEmailForm = true,
                                }) => {
    const [sendEmail, setSendEmail] = useState(false);
    const [emailMessage, setEmailMessage] = useState('');

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm(sendEmail, emailMessage);
        }
    };

    const modalStyle = {
        content: {
            backgroundColor: isPositiveMessage ? 'lightgreen' : 'lightcoral',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
    };

    const renderMessage = () => {
        if (typeof message === 'object') {
            return (
                <ul>
                    {Object.entries(message).map(([key, value]) => (
                        <li key={key}>{value}</li>
                    ))}
                </ul>
            );
        }
        return <p className={styles.modalMessage}>{message}</p>;
    };

    const confirmButtonStyle = confirmButtonColor
        ? { backgroundColor: confirmButtonColor }
        : {};

    const cancelButtonStyle = cancelButtonColor
        ? { backgroundColor: cancelButtonColor }
        : {};

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={modalStyle}
            contentLabel="Reservation Cancel Modal"
            className={styles.modalContent}
            overlayClassName={styles.modalOverlay}
        >
            {renderMessage()}
            {showEmailForm && (
                <>
                    <label>
                        <input
                            type="checkbox"
                            checked={sendEmail}
                            onChange={() => setSendEmail(!sendEmail)}
                        />
                        Wysłać e-mail
                    </label>
                    {sendEmail && (
                        <textarea
                            value={emailMessage}
                            onChange={(e) => setEmailMessage(e.target.value)}
                            placeholder="Treść e-maila"
                            rows="4"
                            style={{ width: '100%', marginTop: '10px' }}
                        />
                    )}
                </>
            )}
            {onConfirm && (
                <button
                    onClick={handleConfirm}
                    className={`${styles.modalButton} ${
                        isPositiveMessage
                            ? styles.buttonPositive
                            : styles.buttonNegative
                    }`}
                    style={confirmButtonStyle}
                >
                    {confirmLabel}
                </button>
            )}
            {cancelLabel && (
                <button
                    onClick={onCancel || onRequestClose}
                    className={`${styles.modalButton} ${
                        isPositiveMessage
                            ? styles.buttonPositive
                            : styles.buttonNegative
                    }`}
                    style={cancelButtonStyle}
                >
                    {cancelLabel}
                </button>
            )}
        </Modal>
    );
};

export default ReservationCancelModal;
