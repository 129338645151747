import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import jwt_decode from "jwt-decode";
import CalendarBar from "../CalendarBar/CalendarBar";
import AdminReservationList from "../AdminReservationList/AdminReservationList";
import {
    fetchReservationsByAdmin,
    fetchCourts,
    redirectToLoginIfInvalidToken,
    cancelAdminReservationsAfter,
    blockAdminAllFreeCells,
    sendAdminCancellationEmails, updateUsersWithPayedToDate, fetchReservations, getUserData
} from "../../api/api";
import styles from './AdminReservations.module.css';
import moment from 'moment';
import 'moment/locale/pl';
import CustomModal from "../../UI/Modal/CustomModal";
import ReservationCancelModal from "../../UI/Modal/ReservationCancelModal/ReservationCancelModal";
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const AdminReservations = () => {

    const [selectedDate, setSelectedDate] = useState(moment().toDate());
    const [reservations, setReservations] = useState([]);
    const [courts, setCourts] = useState([]);
    const [userId, setUserId] = useState(null);
    const [showAfter14, setShowAfter14] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState({});
    const [userFetched, setUserFetched] = useState(false);

    const startHour = 7;
    const endHour = 22;

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const data = await getUserData();
                setUserData(data);
                setUserFetched(true);
            } catch (error) {
                console.error('Failed to fetch user data:', error);
                setError('Błąd pobrania danych użytkownika');
                setUserFetched(true);
            }
        };
        fetchUserData();
    }, []);

    const canUserSeeAllReservations = () => {
        const userRoles = new Set(userData.authorities || []);
        return userRoles.has("ROLE_ADMIN") || userRoles.has("ROLE_VIEW");
    };

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (!userFetched) return;

            if (!canUserSeeAllReservations()) {
                setError('Brak uprawnień');
                setIsLoading(false);
                return;
            }

            try {
                setIsLoading(true);
                const token = localStorage.getItem('token');
                if (token) {
                    const data = await fetchReservationsByAdmin(selectedDate, navigate);
                    setReservations(data);
                    setError(null);
                }
            } catch (error) {
                console.error('Failed to fetch reservations:', error);
                setError('Błąd pobrania danych');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [userFetched, selectedDate, navigate]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            fetchCourts(navigate).then((data) => {
                setCourts(data);
            });
        }
    }, [navigate]);


    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem("token");
            if (!token) {
                navigate("/login");
            }
        };

        window.addEventListener("storage", checkToken);
        return () => window.removeEventListener("storage", checkToken);
    }, [navigate]);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decoded = jwt_decode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds

                if (decoded.exp && decoded.exp > currentTime) {
                    setUserId(decoded.sub);
                }
            } catch (error) {
                console.error("Error decoding JWT token:", error);
            }
        }
    }, []);



    const handleDateSelect = (date) => {
        setSelectedDate(date);
    };

    const selectedDateString = moment(selectedDate).format('D MMMM YYYY (dddd)');

    const updateReservations = () => {
        const token = localStorage.getItem("token");
        if (token) {
            fetchReservationsByAdmin(selectedDate).then((data) => {
                setReservations(data);
            });
        }
    };

    const handleBlockButton = () => {
        setIsConfirmationModalOpen(true);
    };

    const handleConfirmBlock = async (sendEmail, emailMessage) => {
        setIsConfirmationModalOpen(false);
        try {
            // Sprawdzenie, czy wybrana data jest dzisiejsza
            const today = moment().startOf('day');
            const momentSelectedDate = moment(selectedDate);
            const isSelectedDateToday = momentSelectedDate.isSame(today, 'day');

            // Ustalamy wartość startTime w zależności od wybranej daty
            const currentHour = moment().format("HH:mm:ss");
            const earlyStartTime = "07:00:00";
            const startTime = isSelectedDateToday ? currentHour : earlyStartTime;

            // Anulowanie wszystkich rezerwacji z startTime > now()
            const cancelResponse = await cancelAdminReservationsAfter(selectedDate, startTime);

            // Zablokowanie wszystkich anulowanych komórek
            await blockAdminAllFreeCells(selectedDate, startTime);

            // Aktualizacja wartości payedToDate dla użytkowników
            const updatedCount = await updateUsersWithPayedToDate(selectedDate);

            // Jeśli nie ma żadnych rezerwacji do anulowania, zakończ proces
            if (cancelResponse.data === "Nie znaleziono rezerwacji do anulowania.") {
                setModalMessage(`Nie znaleziono rezerwacji do anulowania. Komórki zablokowane. Przedłużono daty dla ${updatedCount} użytkowników.`);
                setIsModalOpen(true);
                return;
            }

            // Wysłanie maili do osób, którym anulowano rezerwacje
            if (sendEmail) {
                await sendAdminCancellationEmails(cancelResponse.data, selectedDate, emailMessage);
            }


            // Wyświetlenie CustomModal z potwierdzeniem wykonania operacji
            setModalMessage(`Rezerwacje zablokowane i maile wysłane. Przedłużono daty dla ${updatedCount} użytkowników.`);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error in handleConfirmBlock:", error);
            // Wyświetlenie CustomModal z informacją o błędzie
            setModalMessage("Wystąpił błąd podczas blokowania rezerwacji lub wysyłania maili");
            setIsModalOpen(true);
        }
    };

    const isFutureDate = moment(selectedDate).isSameOrAfter(moment().startOf('day'));

    if (error) return <ErrorMessage error={error}/>;

    return (
        canUserSeeAllReservations() ? (
                <>
                    <div className={styles.container}>
                        <CalendarBar
                            onDateSelect={handleDateSelect}
                            value={selectedDate}
                            showPastDates={true}
                        />
                        {isLoading &&
                            <Spinner size={"large"}/>
                        }
                        <div className={styles.dateAndCheckboxContainer}>
                            <div className={styles.dateInfo}>
                                <div>{selectedDateString}</div>
                            </div>
                            <div className={styles.checkboxAfter14}>
                                <input
                                    type="checkbox"
                                    id="showAfter14"
                                    checked={showAfter14}
                                    onChange={(e) => setShowAfter14(e.target.checked)}
                                />
                                <label htmlFor="showAfter14">od 14:00</label>
                            </div>
                            {isFutureDate && <button className={styles.blockButton} onClick={handleBlockButton} >Zablokuj</button>}
                        </div>
                        <AdminReservationList
                            courts={courts}
                            selectedDate={selectedDate}
                            reservations={reservations}
                            userId={userId}
                            startHour={startHour}
                            endHour={endHour}
                            updateReservations={updateReservations}
                            showAfter14={showAfter14}
                        />
                    </div>
                    <ReservationCancelModal
                        isOpen={isConfirmationModalOpen}
                        onRequestClose={() => setIsConfirmationModalOpen(false)}
                        message="Czy na pewno chcesz zablokować rezerwacje i wysłać maile anulujące?"
                        isPositiveMessage={true}
                        confirmLabel="Zablokuj"
                        onConfirm={(sendEmail, emailMessage) => handleConfirmBlock(sendEmail, emailMessage)}
                        confirmButtonColor="red"
                        cancelLabel="Anuluj"
                        onCancel={() => setIsConfirmationModalOpen(false)}
                        cancelButtonColor="green"
                    />

                    <CustomModal
                        isOpen={isModalOpen}
                        onRequestClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                        isPositiveMessage={modalMessage.includes("Rezerwacje")}
                        cancelLabel="OK"
                    >
                        <div>Trwa blokowanie rezerwacji i wysyłanie maili...</div>
                    </CustomModal>
                </>
            ):
            (<p className={styles.access}>Brak uprawnień</p>)

    );
};

export default AdminReservations;