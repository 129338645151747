import React, { useState } from "react";
import axios from "axios";
import config from "./../../config/config";
import Input from "../../UI/Input/Input";
import CustomModal from "../../UI/Modal/CustomModal";
import styles from './ResetPasswordForm.module.css'
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Spinner from "../Spinner/Spinner";


const ResetPasswordForm = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [isPositiveMessage, setIsPositiveMessage] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const { token } = useParams();
    const navigate = useNavigate();


    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            const response = await axios.post(`${config.apiUrl}/reset-password/confirm`, {
                token,
                newPassword,
                confirmNewPassword,
            });

            setModalMessage(response.data);
            setIsPositiveMessage(true);
            setIsModalOpen(true);
            setIsLoading(false);
        } catch (error) {
            setModalMessage(error.response.data);
            setIsPositiveMessage(false);
            setIsModalOpen(true);
            setIsLoading(false);
        }
    };

    if (isLoading) return <Spinner size={"large"}/>;

    return (
        <>
            <form className={styles.resetPasswordForm} onSubmit={handleSubmit}>

                <div className={styles.passwordContainer}>
                    <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="Nowe hasło"
                        value={newPassword}
                        onChange={(event) => setNewPassword(event.target.value)}
                        required
                    />
                    <span className={styles.eyeIcon} onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
    </span>
                </div>
                <div className={styles.passwordContainer}>
                    <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="Potwierdź nowe hasło"
                        value={confirmNewPassword}
                        onChange={(event) => setConfirmNewPassword(event.target.value)}
                        required
                    />
                    <span className={styles.eyeIcon} onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
    </span>
                </div>

                <button  type="submit">Zmień hasło</button>
            </form>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    setIsModalOpen(false);
                    if (isPositiveMessage) {
                        navigate('/login');
                    }
                }}
                message={modalMessage}
                isPositiveMessage={isPositiveMessage}
                cancelLabel="Zamknij"
                confirmLabel="OK"
            />
        </>
    );
};

export default ResetPasswordForm;
