import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
    fetchParticipants,
    unRegisterTournamentParticipant,
    fetchTournament,
    startTournament, deleteMatches, registerTournamentParticipantBySupervisor
} from "../../api/api";
import style from "./AdminStartTournament.module.css";
import CustomModal from "../../UI/Modal/CustomModal";
import Spinner from "../Spinner/Spinner";

const AdminStartTournament = () => {
    const [tournament, setTournament] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [confirmLabel, setConfirmLabel] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [userEmail, setUserEmail] = useState(null);
    const [participantId, setParticipantId] = useState(null);
    const [competitionSystem, setCompetitionSystem] = useState('roundRobin');
    const [seededPlayers, setSeededPlayers] = useState({});
    const [partnerEmail, setPartnerEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const { tournamentId } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchTournamentData = async () => {
            const tournamentResult = await fetchTournament(tournamentId);
            if (tournamentResult.success) {
                setTournament(tournamentResult.data);
            } else {
                setIsLoading(false);
            }
        };

        fetchTournamentData();
    }, [tournamentId]);

    useEffect(() => {
        if (!tournament) return;

        const fetchData = async () => {
            const result = await fetchParticipants(tournamentId);
            if (result.success) {
                let sortedParticipants = [...result.data]; // Kopiujemy dane uczestników

                if (tournament.type === 'SINGLES') {
                    sortedParticipants.sort((a, b) => b.eloSingle - a.eloSingle);
                } else if (tournament.type === 'DOUBLES') {
                    sortedParticipants.sort((a, b) => b.eloDoublesAverage - a.eloDoublesAverage);
                }

                setParticipants(sortedParticipants);
            }
            setIsLoading(false);
        };

        fetchData();
    }, [tournament, tournamentId]);

    const handleCloseModal = () => {
        if(modalMessage ==="Turniej wystartował"){
            navigate("/tournaments");
        }
        setIsModalOpen(false);
        setModalMessage('');
    };

    const handleConfirmAction = async () => {
        const response = await unRegisterTournamentParticipant(participantId);
        if (response.success) {
            setParticipants(response.data.participants);
            setModalMessage("Wypisano");
            setShowConfirmButton(false);
            setIsModalOpen(true);
        } else {
            console.error('Failed to unRegister:', response.data);
            setModalMessage(response.data.message || 'Usunięcie nieudane');
            setShowConfirmButton(false);
            setIsModalOpen(true);
        }
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('pl-PL', options);
    };

    const handleRegister = async () => {
        if (tournament.type === 'DOUBLES' && !partnerEmail) {
            // Handle case where partner email is required but not provided
            setModalMessage("Proszę podać adres email partnera.");
            setIsModalOpen(true);
            return;
        }

        const response = await registerTournamentParticipantBySupervisor(tournament.id, userEmail, partnerEmail);
        if (response.success) {
            setParticipants(response.data.participants);
            setModalMessage(response.data.message);
            setShowConfirmButton(false);
            setIsModalOpen(true);
            setUserEmail('');
            setPartnerEmail('');
        } else {
            console.error('Failed to register:', response.data);
            setModalMessage(response.data || 'Zgłoszenie nieudane');
            setShowConfirmButton(false);
            setIsModalOpen(true);
        }
    };

    const handleUnregister = async (participantId, lastName) => {
        setParticipantId(participantId);
        setModalMessage("Czy napewo chcesz usunąć: " + lastName);
        setConfirmLabel("Tak, usuwam");
        setShowConfirmButton(true);
        setIsModalOpen(true);
    };

    const validSeedsArray = Object.entries(seededPlayers).map(([id, seed]) => ({
        playerId: parseInt(id, 10),
        seedNumber: parseInt(seed, 10),
    }));

    const handleStartTournament = async () => {
        if (!competitionSystem) {
            alert('Please select a competition system to start the tournament.');
            return;
        }

        // Convert the currentSeeds object into an array of SeededPlayerDto objects
        const seedsArray = Object.entries(seededPlayers).map(([id, seed]) => ({
            playerId: parseInt(id, 10),
            seedNumber: parseInt(seed, 10),
        })).filter(seed => seed.seedNumber); // Ensure we only consider entries with a seedNumber

        // If there are no seeds, proceed without further seed-specific validation
        if (seedsArray.length === 0) {
            const response = await startTournament(tournamentId, competitionSystem, []);
            if (response.success) {
                setModalMessage("Turniej wystartował");
                setShowConfirmButton(false);
                setIsModalOpen(true);
            } else {
                console.error('Failed to start tournament:', response.data);
                setModalMessage(response.data || 'Falstart');
                setShowConfirmButton(false);
                setIsModalOpen(true);
            }
            return;
        }

        // Validate seed numbers
        const seedNumbers = seedsArray.map(seed => seed.seedNumber);
        const uniqueSeedNumbers = [...new Set(seedNumbers)];

        // 1. Minimum of two players seeded (only applicable if seeds are provided)
        if (seedNumbers.length < 2) {
            setModalMessage("Min 2 rozstawionych.");
            setShowConfirmButton(false);
            setIsModalOpen(true);
            return;
        }

        // 2. Maximum seeding of 4 players
        if (seedNumbers.length > 4) {
            setModalMessage("Max 4 rozstawionych.");
            setShowConfirmButton(false);
            setIsModalOpen(true);
            return;
        }

        // 3. Valid seed numbers are 1, 2, 3, 4
        const validSeeds = [1, 2, 3, 4];
        const allSeedsValid = seedNumbers.every(num => validSeeds.includes(num));
        if (!allSeedsValid) {
            setModalMessage("Prawidłowe numery to 1, 2, 3, 4.");
            setShowConfirmButton(false);
            setIsModalOpen(true);
            return;
        }

        // 4. Seed numbers cannot be repeated
        if (seedNumbers.length !== uniqueSeedNumbers.length) {
            setModalMessage("Numery nie mogą się powtarzać.");
            setShowConfirmButton(false);
            setIsModalOpen(true);
            return;
        }

        // Proceed to start the tournament with seeds
        const response = await startTournament(tournamentId, competitionSystem, seedsArray);
        if (response.success) {
            setModalMessage("Turniej wystartował");
            setShowConfirmButton(false);
            setIsModalOpen(true);
        } else {
            console.error('Failed to start tournament:', response.data);
            setModalMessage(response.data || 'Falstart');
            setShowConfirmButton(false);
            setIsModalOpen(true);
        }
    };

    const handleDeleteMatches = async () => {
        const response = await deleteMatches(tournamentId);
        if (response.success) {
            setModalMessage("Mecze usunięte.");
            setShowConfirmButton(false);
            setIsModalOpen(true);
        } else {
            console.error('Failed to start tournament:', response.data);
            setModalMessage(response.data || 'Falstart');
            setShowConfirmButton(false);
            setIsModalOpen(true);
        }
    };

    const handleSeededPlayerChange = (participantId, seedNumber) => {
        setSeededPlayers((currentSeeds) => {
            // Convert the currentSeeds object into an array of SeededPlayerDto objects
            const seedsArray = Object.entries(currentSeeds).map(([id, seed]) => ({
                playerId: parseInt(id), // Ensure the ID is an integer
                seedNumber: seed
            }));

            // Find if the participantId already exists in the array
            const existingSeedIndex = seedsArray.findIndex(seed => seed.playerId === participantId);
            if (existingSeedIndex !== -1) {
                // Update the existing seed number
                seedsArray[existingSeedIndex].seedNumber = seedNumber;
            } else {
                // Add the new seeded player
                seedsArray.push({ playerId: participantId, seedNumber: seedNumber });
            }

            // Convert the array back to an object for the state
            const updatedSeeds = {};
            seedsArray.forEach(seed => {
                updatedSeeds[seed.playerId] = seed.seedNumber;
            });

            return updatedSeeds;
        });
    };

    if (isLoading) return <Spinner size={"large"} />;

    return (
        <>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                message={modalMessage}
                confirmLabel={showConfirmButton ? confirmLabel : undefined}
                onConfirm={showConfirmButton ? handleConfirmAction : undefined}
                isPositiveMessage={modalMessage.includes("Dodano") || modalMessage.includes("wystartował") || modalMessage.includes("usunięte") || modalMessage.includes("Wypisano")}
                cancelLabel="Zamknij"
                confirmButtonColor="red"
                cancelButtonColor="green"
            />
            <div className={style.container}>
                {tournament ? (
                    <>
                        <div>
                            <h2 className={style.title}><strong>{tournament.title}</strong></h2>
                            <h2 className={style.title}>Limit graczy: {tournament.participantLimit}</h2>
                        </div>
                        <div className={style.addParticipantContainer}>
                            <input
                                type="email"
                                placeholder="Email"
                                value={userEmail || ''}
                                onChange={(e) => setUserEmail(e.target.value)}
                                className={style.partnerEmailInput}
                            />
                            {
                                tournament.type === 'DOUBLES' && (
                                    <input
                                        type="email"
                                        placeholder="Email partnera"
                                        value={partnerEmail}
                                        onChange={(e) => setPartnerEmail(e.target.value)}
                                        className={style.partnerEmailInput}
                                    />
                                )
                            }
                            <button className={style.addMeButton} onClick={handleRegister}>Dodaj</button>
                        </div>

                        <table className={style.table}>
                            <thead>
                            <tr>
                                <th>Lp.</th>
                                <th>ELO</th>
                                <th>Nazwisko i Imię</th>
                                <th>Data zapisu</th>
                                <th>
                                    {(competitionSystem === 'knockout' || competitionSystem === 'brazilian' || competitionSystem === 'combined') &&
                                        (<span>Nr rozstawienia</span>)
                                    }
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {participants.map((participant, index) => (
                                <tr key={participant.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {tournament.type === 'SINGLES' ? participant.eloSingle : participant.eloDoublesAverage}
                                    </td>
                                    <td>
                                        {participant.lastName} {participant.firstName} <br />
                                        {participant.doublesPartnerLastName} {participant.doublesPartnerFirstName}
                                    </td>
                                    <td>{formatDate(participant.registrationTime)}</td>
                                    <td className={style.actionColumn}>
                                        {(competitionSystem === 'knockout' || competitionSystem === 'brazilian' || competitionSystem === 'combined') && (
                                            <input
                                                type="number"
                                                min="1"
                                                value={seededPlayers[participant.id] || ''}
                                                onChange={(e) => handleSeededPlayerChange(participant.id, e.target.value)}
                                                className={style.seedNumberInput}
                                            />
                                        )}
                                        <button className={style.buttonDelete}
                                                onClick={() => handleUnregister(participant.id, participant.lastName)}>
                                            Usuń
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <div>
                            <select
                                value={competitionSystem}
                                onChange={(e) => setCompetitionSystem(e.target.value)}
                                className={style.competitionSelect}
                            >
                                <option value="roundRobin">Każdy z każdym</option>
                                <option value="knockout">System pucharowy</option>
                                <option value="brazilian">System brazylijski</option>
                                <option value="combined">Faza grupowa + faza pucharowa</option>
                            </select>
                            <button className={style.startTournamentButton} onClick={handleStartTournament}>
                                Start
                            </button>
                            <button className={style.deleteMatchesButton} onClick={handleDeleteMatches}>
                                Usuń mecze
                            </button>
                        </div>
                    </>
                ) : (
                    <p>Loading tournament data...</p>
                )}
            </div>
        </>
    );
};

export default AdminStartTournament;
