import React from "react";
import styles from "./Camera.module.css";

const Camera = () => {

    const imageUrl = "https://btt.org.pl/camera/current.jpg";

    return (

        <div className={styles.cameraWrapper}>
            <img className={styles.centeredImage} src={imageUrl} alt="courtView" />
        </div>
    );
}
export default Camera;

