import React, { useState, useEffect } from 'react';
import { fetchUsers, updateUserByAdmin } from '../../api/api';
import styles from './AdminUsersForm.module.css';
import CustomModal from "../../UI/Modal/CustomModal";
import Spinner from "../Spinner/Spinner";

const AdminUsersForm = () => {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    const getUsers = async () => {
        try {
            const fetchedUsers = await fetchUsers();
            if (Array.isArray(fetchedUsers)) {
                setUsers(fetchedUsers);
            } else {
                console.error('Fetched data is not an array:', fetchedUsers);
                setUsers([]); // Ustaw pustą tablicę, aby uniknąć błędów
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

     const handleReservationToggle = async (id, enabledReservation, lastName) => {
        setModalAction(() => () => updateUserByAdmin(id, { enabledReservation: !enabledReservation }));
        setShowModal(true);
        setLastName(lastName);
    };

    const handleMembershipToggle = async (id, isMember, lastName) => {
        setModalAction(() => () => updateUserByAdmin(id, { isMember: !isMember }));
        setShowModal(true);
        setLastName(lastName);
    };

    const handleCoachshipToggle = async (id, isCoach, lastName) => {
        setModalAction(() => () => updateUserByAdmin(id, { isCoach: !isCoach }));
        setShowModal(true);
        setLastName(lastName);
    };

    const handleDateChange = async (id, newDate) => {
        try {
            await updateUserByAdmin(id, { payedToDate: newDate || null });
            getUsers();
        } catch (error) {
            console.error('Błąd podczas aktualizacji daty płatności użytkownika.', error);
        }
    };


    const confirmModalAction = async () => {
        setShowModal(false);
        try {
            await modalAction();
            getUsers();
        } catch (error) {
            console.error('Błąd podczas wykonywania akcji modalu.', error);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setLastName(null);
    };

    if (isLoading) return <Spinner size={"large"}/>;

    return (
        <>
            <CustomModal
                isOpen={showModal}
                onRequestClose={closeModal}
                message={`Czy na pewno chcesz wykonać tę akcję na ${lastName} ?`}
                confirmLabel="Wykonaj"
                onConfirm={confirmModalAction}
                confirmButtonColor="green"
                cancelLabel="Anuluj"
                cancelButtonColor="red"
                onCancel={closeModal}
            />
            <div className={styles.superAdmin}>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <th>Email</th>
                        <th>Telefon</th>
                        <th>Imię</th>
                        <th>Nazwisko</th>
                        <th>Rezerwacje</th>
                        <th>BTT</th>
                        <th>Coach</th>
                        <th>Opłata</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users
                        .sort((a, b) => a.lastName.localeCompare(b.lastName))
                        .map((user) => (
                            <tr key={user.id}>
                                <td>{user.username}</td>
                                <td>{user.phone}</td>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={user.enabledReservation}
                                        onChange={() => handleReservationToggle(user.id, user.enabledReservation, user.lastName)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={user.member}
                                        onChange={() => handleMembershipToggle(user.id, user.member, user.lastName)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={user.coach}
                                        onChange={() => handleCoachshipToggle(user.id, user.coach, user.lastName)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="date"
                                        value={user.payedToDate ? user.payedToDate : ""}
                                        onChange={(event) => handleDateChange(user.id, event.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>

    );

};

export default AdminUsersForm;
