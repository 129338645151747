import React, {useEffect, useState} from 'react';
import styles from '../TourCur/TourCur.module.css';
import TourCurForm from "../TourCurForm/TourCurForm";
import {fetchTournaments} from "../../api/api";
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const TourArch = () => {
    const [groupedTournaments, setGroupedTournaments] = useState({});
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedTournament, setSelectedTournament] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompletedTournaments = async () => {
            try {
                const result = await fetchTournaments('COMPLETED');
                if (result.success) {
                    const sortedTournaments = result.data.sort((a, b) => {
                        return new Date(b.tournamentDate) - new Date(a.tournamentDate);
                    });

                    // Group tournaments by year
                    const grouped = sortedTournaments.reduce((acc, tournament) => {
                        const year = new Date(tournament.tournamentDate).getFullYear();
                        if (!acc[year]) {
                            acc[year] = [];
                        }
                        acc[year].push(tournament);
                        return acc;
                    }, {});

                    setGroupedTournaments(grouped);

                    // Automatically select the largest (most recent) year
                    const years = Object.keys(grouped).map(Number);
                    const mostRecentYear = Math.max(...years);
                    setSelectedYear(mostRecentYear);

                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Failed to fetch completed tournaments", error);
                setError("Błąd pobrania danych");
                setIsLoading(false);
            }
        };

        fetchCompletedTournaments();
    }, []);

    const handleReturnToList = () => {
        setSelectedTournament(null);
    };

    if (isLoading) return <Spinner size={"large"} />;
    if (error) return <ErrorMessage error={error} />;

    return (
        <div>
            {selectedTournament && (
                <TourCurForm
                    tournament={selectedTournament}
                    isArchived={true}
                    onReturn={handleReturnToList}
                />
            )}
            {!selectedTournament && (
                <div className={styles.filterButtonsContainer}>
                    {/* Render year filter buttons */}
                    {Object.keys(groupedTournaments).map((year) => (
                        <button
                            key={year}
                            className={`${styles.yearButton} ${selectedYear == year ? styles.active : ''}`}
                            onClick={() => setSelectedYear(Number(year))}
                        >
                            {year}
                        </button>
                    ))}
                </div>
            )}
            <div className={styles.tournaments}>
                {!selectedTournament && groupedTournaments[selectedYear] && groupedTournaments[selectedYear].length > 0 ? (
                    groupedTournaments[selectedYear].map((tournament) => (
                        <button
                            key={tournament.id}
                            className={`${styles.tournamentButton} ${styles.inactive}`}
                            onClick={() => setSelectedTournament(tournament)}
                        >
                            {`${tournament.title}`}
                        </button>
                    ))
                ) : (
                    !selectedTournament && <p className={styles.noTournamentsMessage}>Brak zakończonych turniejów.</p>
                )}
            </div>
        </div>
    );
};

export default TourArch;