import React, { useState, useContext } from "react";
import axios from "axios";
import styles from "./LoginForm.module.css";
import Input from "../../UI/Input/Input";
import config from './../../config/config';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from "../../contexts/AuthContext";
import CustomModal from "../../UI/Modal/CustomModal";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {fetchReservationsForPayment, fetchUnpaidFees} from "../../api/api";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from "../Spinner/Spinner";



const LoginForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [emailForReset, setEmailForReset] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isPositiveMessage, setIsPositiveMessage] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    }

    const {setIsLoggedIn} = useContext(AuthContext);

    const location = useLocation();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            const response = await axios.post(`${config.apiUrl}/login`, {
                username,
                password,
            });
            localStorage.setItem('token', response.data.token);
            setIsLoggedIn(true);
            setModalMessage(response.data);
            setIsLoading(false);

            const from = location.state?.from || "/";
            const reservationsForPayment = await fetchReservationsForPayment();
            const unpaidFee = await  fetchUnpaidFees();
            if (reservationsForPayment.length > 0 || unpaidFee.length > 0) {
                navigate("/my-profile/payments");
            } else {
                navigate(from);
            }

        } catch (error) {
            console.log(error.response.data);
            setModalMessage(error.response.data);
            setIsPositiveMessage(false);
            setIsModalOpen(true);
            setIsLoading(false);
        }
    };

    const handleForgotPasswordClick = () => {
        setShowForgotPasswordForm(true);
    };

    const handleResetPasswordSubmit = async (event) => {
        event.preventDefault();

        if (!captchaValue) {
            setModalMessage("Proszę zaznaczyć pole reCAPTCHA.");
            setIsPositiveMessage(false);
            setIsModalOpen(true);
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.post(`${config.apiUrl}/reset-password`, {
                email: emailForReset
            });
            setModalMessage(response.data);
            setIsPositiveMessage(true);
            setIsModalOpen(true);
            setIsLoading(false);
        } catch (error) {
            setModalMessage("Brak komunikacji z serwerem. Spróbuj ponownie później.");
            setIsPositiveMessage(false);
            setIsModalOpen(true);
            setIsLoading(false);
        }
    };

    if (isLoading) return <Spinner size={"large"}/>;

    return (
        <>
            {showForgotPasswordForm && (
                <form className={`${styles.loginForm} ${styles.resetPasswordForm}`} onSubmit={handleResetPasswordSubmit}>
                    <Input
                        type="email"
                        placeholder="Email"
                        value={emailForReset}
                        onChange={(event) => setEmailForReset(event.target.value)}
                        required
                    />
                    <ReCAPTCHA
                        className={styles.recaptcha}
                        sitekey="6LeTikYnAAAAAOF2cNqyfcUGpxRf8S-w_Rx95sK8"
                        onChange={handleCaptchaChange}
                        language="pl"
                    />
                    <button className={styles.buttonReset} type="submit">Resetuj hasło</button>
                </form>
            )}

            {!showForgotPasswordForm && (
                <form className={styles.loginForm} onSubmit={handleSubmit}>
                    {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                    <h2 className={styles.loginTitle}>Logowanie</h2>
                    <Input
                        type="email"
                        placeholder="Email"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        required
                    />
                    <div className={styles.passwordContainer}>
                        <Input
                            type={showPassword ? "text" : "password"}
                            placeholder="Hasło"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            required
                        />
                        <span className={styles.eyeIcon} onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
    </span>
                    </div>
                    <button type="submit" className={styles.button}>Zaloguj</button>
                    <button
                        type="button"
                        className={styles.forgotPassword}
                        onClick={handleForgotPasswordClick}
                    >
                        Nie pamiętam hasła
                    </button>
                    <button
                        type="button"
                        className={styles.registerButton}
                        onClick={() => navigate("/register")}
                    >
                        Zarejestruj się
                    </button>
                </form>
            )}
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    setIsModalOpen(false);
                    if (isPositiveMessage) {
                        navigate('/');
                    }
                }}
                message={modalMessage}
                isPositiveMessage={isPositiveMessage}
                cancelLabel="Zamknij"
            />
        </>
    );
};

export default LoginForm;
