import React, {useEffect, useState} from 'react';
import styles from './MatchesTable.module.css';
import {
    renderMatchResult,
    checkIfResultsEntered,
    renderPlayerName,
    renderPartnerName,
} from "../../../utils";
import {getUserData} from "../../../api/api";
import {FaEnvelope, FaFacebookMessenger, FaPhone} from "react-icons/fa";

const MatchesTable = ({matches, onEditMatch, isArchived, isFinal}) => {
    const [userData, setUserData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUserData();
            setUserData(data);
        };
        fetchData();
    }, []);

    const canUserEditMatch = (match) => {
        const userRoles = new Set(userData.authorities || []);
        const isUserAdminOrViewer = userRoles.has("ROLE_ADMIN") || userRoles.has("ROLE_SUPERVISOR");
        const isUserParticipant = match.userId1 === userData.id || match.userId2 === userData.id || match.userId1Partner === userData.id || match.userId2Partner === userData.id;

        return isUserAdminOrViewer || isUserParticipant;
    };

    const handleContactClick = (contact, type) => {
        if (type === 'email') {
            window.location.href = `mailto:${contact}`;
        } else if (type === 'phone') {
            window.location.href = `tel:${contact}`;
        } else if (type === 'messenger') {
            window.location.href = `https://m.me/${contact}`;
        }
    };

    return (
        <div className={styles.container}>
            <h5 className={styles.title}>Mecze</h5>
            <div className={styles.gridContainer}>
                {matches.map((match, index) => (
                    <div key={match.id} className={styles.card}>
                        <div className={styles.cardHeader}>
                            <span>{index + 1}</span>
                            {!isArchived && canUserEditMatch(match) && !isFinal && (
                                <button className={styles.button} onClick={() => onEditMatch(match.id)}>
                                    {checkIfResultsEntered(match) ? 'Edytuj' : 'Wprowadź'}
                                </button>
                            )}
                        </div>
                        <div className={styles.result}>
                            <div>
                                <div className={styles.playerNames}>
                                    <div>
                                        {renderPlayerName(match.participant1Id, match.participant1LastName, match.participant1FirstName, match)}
                                        {match.participant1PartnerLastName && (
                                            <>
                                                <br/>
                                                <span className={styles.partnerName}>
                                            {renderPartnerName(match.participant1Id, match.participant1PartnerLastName, match.participant1PartnerFirstName, match)}
                                        </span>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        {renderPlayerName(match.participant2Id, match.participant2LastName, match.participant2FirstName, match)}
                                        {match.participant2PartnerLastName && (
                                            <>
                                                <br/>
                                                <span className={styles.partnerName}>
                                            {renderPartnerName(match.participant2Id, match.participant2PartnerLastName, match.participant2PartnerFirstName, match)}
                                        </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                {match.setResults && renderMatchResult(match.setResults)}
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    );
};

export default MatchesTable;
