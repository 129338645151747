import React from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import AdminNewsForm from "../AdminNewsForm/AdminNewsForm";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import AdminUsersForm from "../AdminUsersForm/AdminUsersForm";
import AdminReservations from "../AdminReservations/AdminReservations";
import AllUsersReservations from "../AllUsersReservations/AllUsersReservations";
import AdminPayments from "../AdminPayments/AdminPayments";
import PaymentOnLine from "../PaymentOnLine/PaymentOnLine";
import style from './AdminConsole.module.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export default function AdminConsole() {
    const [value, setValue] = React.useState(0);


    const navigate = useNavigate();

    const isAuthenticated = () => {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        // Sprawdzanie ważności tokenu
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const decodedToken = JSON.parse(window.atob(base64));
        const expirationDate = new Date(decodedToken.exp * 1000);

        return expirationDate > new Date();
    };
    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login');
        }
    }, [navigate]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={style.container}>
            <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Sch" className={style.myTab} {...a11yProps(0)} />
                <Tab label="Res" className={style.myTab} {...a11yProps(1)} />
                <Tab label="Use" className={style.myTab} {...a11yProps(2)} />
                <Tab label="New" className={style.myTab} {...a11yProps(3)} />
                <Tab label="Pay" className={style.myTab} {...a11yProps(4)} />
                <Tab label="P24" className={style.myTab} {...a11yProps(5)} />

            </Tabs>
            <TabPanel value={value} index={0}>
                <AdminReservations/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AllUsersReservations/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AdminUsersForm/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <AdminNewsForm />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <AdminPayments />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <PaymentOnLine />
            </TabPanel>
        </div>
    );
}
