import React, { createContext, useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decoded = jwt_decode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds

                if (decoded.exp && decoded.exp > currentTime) {
                    setIsLoggedIn(true);
                }
            } catch (error) {
                console.error("Error decoding JWT token:", error);
            }
        }
    }, []);

    const value = {
        isLoggedIn,
        setIsLoggedIn
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
