import React, { useEffect, useState } from 'react';
import styles from './EloRanking.module.css';
import { fetchEloHistory, fetchEloRanking, fetchMatchById } from "../../api/api";
import Spinner from "../Spinner/Spinner";
import {
    formatDateDDMMYY,
    formatFirstLetterOfName,
    renderMatchResult,
    renderPartnerName,
    renderPlayerName
} from "../../utils";
import {IoArrowUndoOutline, IoInformationCircleOutline} from "react-icons/io5";

const EloRanking = () => {
    const [eloSingles, setEloSingles] = useState([]);
    const [eloDoubles, setEloDoubles] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [eloHistory, setEloHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [view, setView] = useState('SINGLES'); // Default to SINGLES view
    const [selectedUserName, setSelectedUserName] = useState('');
    const [matchDetails, setMatchDetails] = useState(null);

    useEffect(() => {
        const fetchEloData = async () => {
            try {
                const singles = await fetchEloRanking('SINGLES');
                const doubles = await fetchEloRanking('DOUBLES');
                setEloSingles(singles);
                setEloDoubles(doubles);
            } catch (error) {
                console.error("Failed to fetch elo ranking", error);
                setError("Błąd pobrania danych");
            } finally {
                setLoading(false);
            }
        }
        fetchEloData();
    }, []);

    const loadEloHistory = async (userId, matchType) => {
        setLoading(true);
        try {
            const result = await fetchEloHistory(userId, matchType);
            if (result.success) {
                const sortedHistory = result.data.sort((a, b) => b.id - a.id);
                setEloHistory(sortedHistory);
            }
        } catch (error) {
            console.error("Failed to fetch elo history:", error);
            setError("Błąd pobrania danych");
        } finally {
            setLoading(false);
        }
    }

    const loadMatchDetails = async (matchId) => {
        setLoading(true);
        try {
            const result = await fetchMatchById(matchId);
            if (result.success) {
                setMatchDetails(result.data);
            }
        } catch (error) {
            console.error("Failed to fetch match details:", error);
            setError("Błąd pobrania danych");
        } finally {
            setLoading(false);
        }
    };

    const handleEloClick = (userId, matchType, userName) => {
        setSelectedUser({ userId, matchType });
        setSelectedUserName(userName);
        loadEloHistory(userId, matchType);
    };

    const handleBackClick = () => {
        setSelectedUser(null);
        setEloHistory([]);
        setMatchDetails(null);
    };

    const handleCloseModal = () => {
        setMatchDetails(null);
    };

    const renderRankingTable = (data, matchType) => (
        <table className={styles.table}>
            <thead>
            <tr>
                <th>ELO</th>
                <th>Gracz</th>
            </tr>
            </thead>
            <tbody>
            {data.map((user) => (
                <tr key={user.userId}>
                    <td>
                        <a href="#" onClick={() => handleEloClick(user.userId, matchType, `${user.lastName} ${formatFirstLetterOfName(user.firstName)}`)}>{user.elo}</a>
                    </td>
                    <td>{user.lastName} {formatFirstLetterOfName(user.firstName)}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );

    if (loading) return <Spinner size={"large"}/>;

    return (
        <div className={styles.container}>
            {error && <div>Error: {error}</div>}
            {selectedUser ? (
                <div className={styles.historyContainer}>
                    <button onClick={handleBackClick} className={styles.backButton}><IoArrowUndoOutline /></button>
                    <h5 className={styles.title}>Historia elo {selectedUser.matchType === 'SINGLES' ? 'singiel' : 'debel'}- {selectedUserName}</h5>
                    <table className={styles.table}>
                        <thead>
                        <tr>
                            <th>ELO</th>
                            <th>+/-</th>
                            <th>Data</th>
                            <th>Oponenci</th>
                        </tr>
                        </thead>
                        <tbody>
                        {eloHistory.map((entry, index) => (
                            <tr key={index}>
                                <td>{entry.elo}</td>
                                <td>
                                    <a href="#" onClick={() => loadMatchDetails(entry.matchId)}>{entry.eloChange}</a>
                                </td>
                                <td>{formatDateDDMMYY(entry.date)}</td>
                                <td>
                                    {entry.opponent}
                                    {entry.opponentPartner && ` / ${entry.opponentPartner}`}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {matchDetails && (
                        <div className={styles.modal}>
                            <div className={styles.modalContent}>
                                <span>{matchDetails.tournamentTitle}</span>
                                <span className={styles.closeButton} onClick={handleCloseModal}>&times;</span>
                                <div className={styles.result}>
                                    <div>
                                        <div className={styles.playerNames}>
                                            <div>
                                                {renderPlayerName(matchDetails.participant1Id, matchDetails.participant1LastName, matchDetails.participant1FirstName, matchDetails)}
                                                {matchDetails.participant1PartnerLastName && (
                                                    <>
                                                        <br/>
                                                        <span className={styles.partnerName}>
                                                            {renderPartnerName(matchDetails.participant1Id, matchDetails.participant1PartnerLastName, matchDetails.participant1PartnerFirstName, matchDetails)}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                {renderPlayerName(matchDetails.participant2Id, matchDetails.participant2LastName, matchDetails.participant2FirstName, matchDetails)}
                                                {matchDetails.participant2PartnerLastName && (
                                                    <>
                                                        <br/>
                                                        <span className={styles.partnerName}>
                                                            {renderPartnerName(matchDetails.participant2Id, matchDetails.participant2PartnerLastName, matchDetails.participant2PartnerFirstName, matchDetails)}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div >
                                        {matchDetails.setResults && renderMatchResult(matchDetails.setResults)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className={styles.rankings}>

                    <div className={styles.switch}>
                        <button
                            className={view === 'SINGLES' ? styles.active : ''}
                            onClick={() => setView('SINGLES')}
                        >
                            Singiel
                        </button>
                        <button
                            className={view === 'DOUBLES' ? styles.active : ''}
                            onClick={() => setView('DOUBLES')}
                        >
                            Debel
                        </button>
                    </div>
                    {view === 'SINGLES' && (
                        <div className={styles.ranking}>
                            <div className={styles.titleInfo}>
                                <h5 className={styles.title}>Gra singlowa</h5>
                                <div className={styles.buttonOfDescription}>
                                    <a href="/elo-description" target="_blank"><IoInformationCircleOutline /></a>
                                </div>
                            </div>
                            {renderRankingTable(eloSingles, 'SINGLES')}
                        </div>
                    )}
                    {view === 'DOUBLES' && (
                        <div className={styles.ranking}>
                            <div className={styles.titleInfo}>
                                <h5 className={styles.title}>Gra deblowa</h5>
                                <div className={styles.buttonOfDescription}>
                                    <a href="/elo-description" target="_blank"><IoInformationCircleOutline/></a>
                                </div>
                            </div>

                            {renderRankingTable(eloDoubles, 'DOUBLES')}
                        </div>
                    )}


                </div>
            )}
        </div>
    );
};

export default EloRanking;
