import React, { useState, useEffect } from 'react';
import { fetchNews } from "../../api/api";
import { format } from 'date-fns';
import styles from './News.module.css';
import Spinner from "../../components/Spinner/Spinner";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

const News = () => {
    const [news, setNews] = useState([]);
    const [expandedId, setExpandedId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedNews = await fetchNews();
            const sorted = fetchedNews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setNews(sorted);
            setIsLoading(false);
            if (sorted.length > 0) {
                setExpandedId(sorted[0].id); // Set the first item to be expanded by default
            }
        };

        fetchData();
    }, []); // Ensures this effect runs only once when the component mounts

    const toggleExpand = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    if (isLoading) return <Spinner size={"large"}/>;

    return (
        <div className={styles.newsList}>
            {news.length === 0 && <ErrorMessage error={"Brak komunikacji z serwerem"}/>}
            {news.map((item) => (
                <div key={item.id} className={styles.newsItem} onClick={() => toggleExpand(item.id)}>
                    <p className={styles.newsDate}>
                        {format(new Date(item.createdAt), 'dd-MM-yyyy')}
                    </p>
                    <h3 className={styles.newsTitle}>{item.title}</h3>
                    {expandedId === item.id && <p className={styles.newsDescription}>{item.description}</p>}
                </div>
            ))}
        </div>
    );
};

export default News;
