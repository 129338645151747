import React from 'react';
import Modal from 'react-modal';
import styles from './CustomModal.module.css';

const CustomModal = ({
                         isOpen,
                         onRequestClose,
                         message,
                         isPositiveMessage,
                         confirmLabel,
                         onConfirm,
                         confirmButtonColor,
                         cancelLabel,
                         onCancel,
                         cancelButtonColor,
                         showConfirmButton = true
                     }) => {
    const modalStyle = {
        content: {
            backgroundColor: isPositiveMessage ? 'lightgreen' : 'lightcoral',
            textAlign: 'center', // Centers text horizontally
            display: 'flex', // Enables flexbox layout
            flexDirection: 'column', // Stacks children vertically
            justifyContent: 'center', // Centers children vertically in the container
            alignItems: 'center', // Centers children horizontally in the container
            padding: '20px', // Example padding; adjust as needed
            overflow: 'auto', // Adds scroll to the content if it exceeds the height
            height: 'auto', // Adjust based on your needs or use 'auto' for flexible height
            maxHeight: '80%', // Prevents modal from being too tall; adjust as needed
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
    };

    const renderMessage = () => {
        if (typeof message === 'object') {
            return (
                <ul>
                    {Object.entries(message).map(([key, value]) => (
                        <li key={key}>{value}</li>
                    ))}
                </ul>
            );
        }
        return <p className={styles.modalMessage}>{message}</p>;
    };

    const confirmButtonStyle = confirmButtonColor
        ? { backgroundColor: confirmButtonColor }
        : {};

    const cancelButtonStyle = cancelButtonColor
        ? { backgroundColor: cancelButtonColor }
        : {};


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={modalStyle}
            contentLabel="Example Modal"
            className={styles.modalContent}
            overlayClassName={styles.modalOverlay}
        >
            {renderMessage()}
            {showConfirmButton && onConfirm &&  (
                <button
                    onClick={onConfirm}
                    className={`${styles.modalButton} ${
                        isPositiveMessage ? styles.buttonPositive : styles.buttonNegative
                    }`}
                    style={confirmButtonStyle}
                >
                    {confirmLabel}
                </button>
            )}
            <button
                onClick={onCancel || onRequestClose}
                className={`${styles.modalButton} ${
                    isPositiveMessage ? styles.buttonPositive : styles.buttonNegative
                }`}
                style={cancelButtonStyle}
            >
                {cancelLabel}
            </button>
        </Modal>
    );
};

export default CustomModal;
