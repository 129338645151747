export const links = [
  {
    id: 1,
    url: '/info',
    text: 'info',
  },
  {
    id: 2,
    url: '/reservations',
    text: 'rezerwacje',
  },
  {
    id: 3,
    url: '/my-profile',
    text: 'dane ',
    requiresAuth: true,

  },
  {
    id: 4,
    url: '/tournaments',
    text: 'Turnieje',
  },
  {
    id: 5,
    url: '/camera',
    text: 'kamera ',
  },
  {
    id: 6,
    url: '/partner',
    text: 'partner',
  },
  {
    id: 7,
    url: '/login',
    text: 'zaloguj',
    requiresNotAuth: true,
  },
  {
    id: 8,
    url: '',
    text: 'Wyloguj',
    requiresAuth: true,
    requiresNotAuth: false,
  }
];

