import React from 'react';
import styles from '../ScoreRules/ScoreRules.module.css';

const NtrpScale = () => {
    return (
        <div className={styles.scoreRulesContainer}>
            <h1>Skala NTRP</h1>

            <p className={styles.preliminaryDescription}>Skala NTRP (National Tennis Rating Program) jest wskaźnikiem
                poziomu umiejętności czysto tenisowych (techniczno-taktycznych).
                Został stworzony przez Federację Tenisową Stanów Zjednoczonych (USTA) w 1979 roku. Wskaźnik ten nie jest
                powiązany ze sprawnością fizyczną,
                dlatego nie jest wykluczone, że zawodnik o niższym NTRP wygra z zawodnikiem o wyższym wskaźniku ze
                względu na kondycję oraz szybkość poruszania się po korcie. </p>

            <div className={styles.scoreRulesText}>

                <h5>NTRP 1.0 </h5>
                <p>Gracz dopiero zaczyna swoją przygodę z tenisem.</p>

                <h5>NTRP 1.5</h5>
                <p>Gracz ma małe doświadczenie w uderzaniu piłek oraz ciągle pracuje nad utrzymaniem piłki w grze
                    niezależnie od ich tempa.
                    Serwis sprawia mu trudność. Ten gracz nie jest jeszcze gotowy do rywalizacji.</p>

                <h5>NTRP 2.0</h5>
                <p>Graczowi brakuje doświadczenia kortowego (ogrania). Ma widoczne braki w technice, ale rozumie
                    podstawy gry singlowej i deblowej.</p>

                <h5>NTRP 2.5</h5>
                <p className={styles.nextRow}>Gracz uczy się oceniać miejsce uderzenia piłki w kort przy jej odbiorze,
                    jednak poruszanie się po korcie wymaga dalszej pracy.
                    Potrafi utrzymać wolne wymiany piłek i zaczyna pokrywać cały kort. Gotowy jest do rywalizacji w
                    meczach i podstawowych turniejach.
                </p>
                <p className={styles.nextRow}><b>Słabości:</b> brak odpowiedniego trzymania rakiety, niepełny zamach
                    przy serwisie,
                    niespójne podrzucenie piłki do serwisu, ograniczona gra przy siatce.</p>

                <h5>NTRP 3.0</h5>
                <p className={styles.nextRow}>Ten gracz jest już dość konsekwentny przy uderzaniu średnio szybkich
                    piłek,
                    ale nie czuje się komfortowo z wszystkimi uderzeniami i brakuje mu precyzji przy próbie kontroli
                    kierunku,
                    głębokości, tempa lub zmiany dystansu uderzeń. Najczęstsza formacja w deblu to jeden z przodu, jeden
                    z tyłu. (w deblu staramy się dążyć do uzyskania i utrzymania linii równoległej do
                    siatki).</p>

                <p className={styles.nextRow}><b>Potencjalne ograniczenia:</b> trudności z tempem uderzeń, może czuć się
                    niekomfortowo przy siatce.</p>


                <h5>NTRP 3.5</h5>
                <p className={styles.nextRow}>Gracz poprawił niezawodność i kontrole kierunku uderzeń przy średnim
                    tempie piłek, ale brakuje mu różnorodności w uderzeniach.
                    Takie zagrania jak efektywne wykorzystanie loba, uderzenia znad głowy, gra przy siatce oraz wolej są
                    ograniczone ze
                    względu na brak pewności.
                    Gracz poprawił grę przy siatce oraz krycie kortu. </p>

                <p className={styles.nextRow}><b>Mocne strony:</b> Gracz na tym poziomie wykorzystuję umiejętność
                    skupienia się, taktyki i strategii gry.</p>

                <h5>NTRP 4.0</h5>
                <p className={styles.nextRow}>Gracz ma pewne uderzenia włączając w to kontrolę kierunku zagrania,
                    głębokości i
                    umiejętność zmiany odległości uderzeń zarówno
                    przy uderzeniach forehandowych jak i backhandowych podczas gry w średnim tempie. Z sukcesem
                    wykorzystuje loby,
                    uderzenia znad głowy,
                    grę przy siatce i woleje. Gracz rzadko popełnia błędy serwisowe. Może przegrywać wymiany przez brak
                    dostatecznego skupienia.</p>

                <p className={styles.nextRow}><b>Mocne strony:</b> niezawodny drugi serwis, wykorzystuje swoje atuty do
                    zdobycia punktu.</p>

                <h5>NTRP 4.5</h5>
                <p className={styles.nextRow}>Gracz zaczyna świadomie zmieniać tempo i rotację piłeki, dobrze porusza
                    się
                    po korcie, potrafi kontrolować odległość i
                    głębokość uderzeń, zaczyna planować grę
                    na podstawie mocnych i słabych stron. Pierwszy serwis jest mocny i celny, nie popełnia błędów przy
                    drugim serwisie.
                    Przy trudnych piłkach ma jeszcze tendencje do grania w aut.</p>

                <p className={styles.nextRow}><b>Mocne strony:</b> o wyniku coraz częściej decyduje serwis,
                    potrafi niwelować swoje słabości poprzez kreowanie gry.</p>

                <h5>NTRP 5.0</h5>
                <p className={styles.nextRow}>Gracz ma zdolność do przewidywania uderzeń przeciwnika, często potrafi
                    grać mocnymi uderzeniami,
                    na czym opiera swoją grę.
                    Potrafi regularnie uderzać zwycięskie piłki lub wymuszać błąd u przeciwnika za pomocą gry skrótami,
                    wolejami, lobami, półwolejami, uderzeniami znad głowy.
                    Pewny pierwszy serwis oraz rotacja piłki przy drugim serwie.</p>

                <p className={styles.nextRow}><b>Mocne strony:</b> dobra decyzyjność, ukrywa swoje słabości,
                    silny psychicznie, ale może popełniać błędy w trudnych sytuacjach.</p>

                <h5>NTRP 5.5</h5>
                <p className={styles.nextRow}>Gracz rozwinął niezawodność i siłę uderzeń jako główną broń. Potrafi
                    zmieniać strategię i styl gry
                    w wymagających tego sytuacjach oraz ma niezawodne uderzenia grając pod presją.</p>

                <p className={styles.nextRow}><b>Mocne strony:</b> potrafi grać ofensywnie w każdej sytuacji,
                    zmienia strategie i styl gry w trudnych sytuacjach.
                    Pomimo presji pierwszy i drugi serwis jest niezawodny.</p>

            </div>

            <br/><br/>

            <h1>Dodatkowe informacje dotyczące formularza</h1>

            <div className={styles.scoreRulesText}>

                <h5>1. Data </h5>
                <p>Można ustawić datę maksymalnie na jednen miesiąc do przodu. Data służy do wyznaczenia terminu gry,
                    oraz
                    do ustalenia ważności ogłoszenia. Po uplywie daty karta jest widoczna tylko dla jej posiadacza.
                    Pozostali nie widzą
                    przeterminowanych kart. Aby ponownie karta była widoczna, jej właściciel może zmienić jej datę na
                    aktualną.</p>

                <h5>2. Edycja i usunięcie </h5>
                <p>Tylko właściciel karty może ją edytować lub usuwać. Usunięcie karty powoduje, że nie będzie już
                    dostępna dla nikogo. Aby tymczasowo ukryć swoją kartę wystarczy zmienić jej datę na wczorajszą.</p>

                <h5>3. Preferowany kontakt </h5>
                <p>Aby można było dodać nową kartę, użytkownik powinien mieć na swoim profilu (Dane) wskazany
                    przynajmniej
                    jeden preferowany kontakt.</p>

                <h5>4. Messenger </h5>
                <p>Aby korzystać z messengera należy wpisać na swoim profilu nazwę użytkownika, podanej w ustawieniach
                    aplikacji 'Messenger' na telefonie.
                    Znajduje się tam parametr 'Nazwa użytkownika'. Może on wyglądać tak: m.me/jan.kowalski.48 W takim
                    przypadku w swoich danych do pola 'Messenger'
                    należy wpisać wartość jan.kowalski.48</p>

            </div>
            <br/><br/>
            <h1>Powiadomienia mailowe o nowym ogłoszeniu</h1>

            <p>W przypadku pojawienia się nowych ogłoszeń poszukiwania partnera do gry stnieje możliwość otrzymywania powiadomień mailowych .
                Aby otrzymywać takie wiadomości, po zalogowaniu się na swoim profilu należy zanaczyć checkBox 'Email 'Szukam partnera do gry' oraz określić
              interesujący nas zakres NTRP.</p>

        </div>
    );
};

export default NtrpScale;