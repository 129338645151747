import React, { createContext, useState, useEffect } from 'react';
import {fetchConfig} from "../api/api";

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState({
        reservationStartTime: '07:00',
        reservationEndTime: '22:00',
        numberOfCourts: 3,
        priceChangeTime: '16:00',
        priceBeforeChange: 18,
        priceAfterChange: 28,
        coachFee: 12,
        // other configuration parameters
    });


    useEffect(() => {

            fetchConfig().then((result) => {
                if (result.success) {
                    setConfig(result.data);
                }
            }).catch();

    }, []);


    return (
        <ConfigContext.Provider value={{config, setConfig}}>
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigContext;