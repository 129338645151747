import axios from "axios";
import moment from "moment";
import config from "../config/config";

const RESERVATIONS_PER_PAGE = 10;
const PAYMENTS_PER_PAGE = 10;


export const deleteExpiredTokens = async () => {
    try {
        const response = await axios.delete(`${config.apiUrl}/super/confirmation-token/expired`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateUserByAdmin = async (id, updates) => {
    const response = await fetch(`${config.apiUrl}/admin/user/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(updates),
    });

    if (!response.ok) {
        throw new Error('Błąd podczas aktualizacji danych użytkownika przez administratora.');
    }

    return await response.json();
};


export const togglePayingInProgress = async (id) => {
    const response = await fetch(`${config.apiUrl}/admin/reservations/togglePaying/${id}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });

    if (!response.ok) {
        throw new Error('Błąd podczas aktualizacji danych użytkownika przez administratora.');
    }

    return await response.json();
};

export const updateUserBySuper = async (id, updates) => {
    const response = await fetch(`${config.apiUrl}/super/user/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(updates),
    });

    if (!response.ok) {
        throw new Error('Błąd podczas aktualizacji danych użytkownika przez administratora.');
    }

    return await response.json();
};

export const fetchUsers = async () => {
    try {
        const token = getToken();
        const response = await axios.get(`${config.apiUrl}/admin/user/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async (id) => {
    try {
        const token = getToken();
        const response = await axios.delete(`${config.apiUrl}/super/user/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export async function updateNews(id, newsData) {
    try {

        const token = getToken();

        const response = await axios.put(`${config.apiUrl}/admin/news/update/${id}`, newsData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function deleteNews(id) {
    try {
        const token = getToken();
        const response = await axios.delete(`${config.apiUrl}/admin/news/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export async function fetchNews() {
    try {
        const response = await axios.get(`${config.apiUrl}/news`);
        return response.data;
    } catch (error) {
        return [];
    }
}

export async function createNews(newsData) {
    try {
        const token = getToken();
        const response = await axios.post(`${config.apiUrl}/admin/news/add`, newsData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function loadReservations(page = 0, reservationType) {
    try {
        const skip = page * RESERVATIONS_PER_PAGE;
        return await fetchUserReservations(reservationType, null, skip, RESERVATIONS_PER_PAGE);
    } catch (error) {
        throw error;
    }
}

export async function loadAllReservations(page = 0, reservationType) {
    try {
        const skip = page * RESERVATIONS_PER_PAGE;
        return await fetchAllUserReservations(reservationType, null, skip, RESERVATIONS_PER_PAGE);
    } catch (error) {
        return [];
    }
}

export function redirectToLoginIfInvalidToken(navigate, from) {
    const token = getToken();
    if (!token || !isTokenValid(token)) {
        console.log("Invalid or missing token. Redirecting to login.", "from: " + from);
        localStorage.removeItem("token");
        navigate("/login", {state: {from}});
        return false;
    }
    return true;
}

export function getToken() {
    return localStorage.getItem("token");
}

export function isTokenValid(token) {
    try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        const currentTime = Math.floor(Date.now() / 1000);

        // Check if the token's expiration time (exp) is greater than the current time
        return payload.exp > currentTime;
    } catch (error) {
        return false;
    }
}

export async function getUserData() {
    try {
        const token = getToken();


        if (!token || !isTokenValid(token)) {
            return {};
        }

        const response = await axios.get(`${config.apiUrl}/user/profile`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function fetchActiveCoaches() {
    try {

        const response = await axios.get(`${config.apiUrl}/active-coaches`);
        return response.data;
    } catch (error) {
        return [];
    }
}


export async function updateUserData(updatedData) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return false;
        }

        await axios.put(`${config.apiUrl}/user/profile`, updatedData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return true;
    } catch (error) {
        throw error
        // return false;
    }
}

export async function updateReservationByAdmin(updatedData) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return false;
        }

        await axios.patch(`${config.apiUrl}/admin/reservation/edit`, updatedData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return true;
    } catch (error) {
        throw error
        // return false;
    }
}

export async function fetchReservations(selectedDate, navigate) {
    try {
        const dateString = selectedDate.toISOString().split("T")[0];
        const token = getToken();

        if (!redirectToLoginIfInvalidToken(navigate)) {
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/reservations?date=${dateString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function fetchReservationsByAdmin(selectedDate, navigate) {
    try {
        const dateString = selectedDate.toISOString().split("T")[0];
        const token = getToken();

        if (!redirectToLoginIfInvalidToken(navigate)) {
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/view/reservations?date=${dateString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function updateUsersWithPayedToDate(selectedDate) {
    try {
        const dateString = selectedDate.toISOString().split("T")[0];
        const token = getToken();


        const response = await axios.patch(
            `${config.apiUrl}/view/updatePayedToDate/users?date=${dateString}`,
            null, // tu można przekazać ciało żądania, jeśli jest potrzebne
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error updating users' payedToDate:", error);
        return 0;
    }
}

export async function loadAllPaymentsByAdmin(page = 0) {
    try {
        const skip = page;
        return await fetchAllAdminPayments(skip, PAYMENTS_PER_PAGE);
    } catch (error) {
        return [];
    }
}

export async function fetchAllAdminPayments(skip, limit) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/admin/payments`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                skip: skip,
                limit: limit,
            },
        });
        return response.data;
    } catch (error) {
        return [];
    }
}

export async function loadAllPaidPayments(page = 0) {
    try {
        const skip = page;
        return await fetchPaidPayments(skip, PAYMENTS_PER_PAGE);
    } catch (error) {
        return [];
    }
}


export async function fetchPaidPayments(skip, limit) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/payments/user/paid`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                skip: skip,
                limit: limit,
            },
        });
        return response.data;
    } catch (error) {
        return [];
    }
}


export async function fetchUnpaidReservations() {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/reservations/user/unpaid`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export async function fetchUnpaidFees() {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/payments/user/unpaid`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function fetchReservationsForPayment() {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/reservations/user/reservations-for-payment`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function fetchAllUnpaidReservations() {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/admin/reservations/unpaid`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function fetchAllUnpaidFees() {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/admin/payments/unpaid`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        return [];
    }
}

export async function markReservationAsPaid(reservationId) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return null;
        }

        const response = await axios.patch(
            `${config.apiUrl}/admin/reservations/${reservationId}/markAsPaid`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        return null;
    }
}

export async function markPaymentAsPaid(paymentId) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return null;
        }

        const response = await axios.patch(
            `${config.apiUrl}/admin/payments/${paymentId}/markAsPaid`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        return null;
    }
}

export async function fetchUserReservations(
    reservationType = "current",
    navigate,
    skip,
    limit
) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            if (navigate) {
                navigate("/login", {replace: true});
            }
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/reservations/user`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                type: reservationType,
                skip: skip,
                limit: limit,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function fetchAllUserReservations(
    reservationType = "current",
    navigate,
    skip,
    limit
) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            if (navigate) {
                navigate("/login", {replace: true});
            }
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/admin/reservations/user`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                type: reservationType,
                skip: skip,
                limit: limit,
            },
        });
        return response.data;
    } catch (error) {
        return [];
    }
}


export async function fetchReservationCount(reservationType) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return 0;
        }

        const response = await axios.get(`${config.apiUrl}/reservations/user/count`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                type: reservationType,
            },
        });
        return response.data.count;
    } catch (error) {
        return 0;
    }
}

export async function fetchAllReservationCount(reservationType) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return 0;
        }

        const response = await axios.get(`${config.apiUrl}/admin/reservations/user/count`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                type: reservationType,
            },
        });
        return response.data.count;
    } catch (error) {
        return 0;
    }
}

export async function fetchAllPaymentsCount() {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return 0;
        }

        const response = await axios.get(`${config.apiUrl}/admin/payments/count`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.count;
    } catch (error) {
        return 0;
    }
}

export async function fetchAllPaymentsPaidCount() {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return 0;
        }

        const response = await axios.get(`${config.apiUrl}/user/payments/paid/count`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.count;
    } catch (error) {
        return 0;
    }
}

export async function fetchCourts(navigate) {
    try {
        const token = getToken();

        if (!redirectToLoginIfInvalidToken(navigate)) {
            return [];
        }

        const response = await axios.get(`${config.apiUrl}/courts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        return [];
    }
}

export async function createReservation(reservationData) {
    try {
        const token = getToken();

        if (!token || !isTokenValid(token)) {
            return [];
        }

        const response = await axios.post(`${config.apiUrl}/reservations`, reservationData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }

}

export async function cancelReservation(reservationId) {
    try {
        const token = getToken();
        const response = await axios.patch(
            `${config.apiUrl}/reservations/cancel/${reservationId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return {success: true, data: response.data};
    } catch (error) {
        throw error;
    }
}

export async function initiatePaymentOnServer(payment) {
    try {
        const token = getToken();
        const response = await axios.post(
            `${config.apiUrl}/payments/initiate`,
            payment,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}


export async function cancelAdminReservation(reservationId, sendEmail, emailMessage) {
    try {
        const token = getToken();
        const response = await axios.patch(
            `${config.apiUrl}/admin/reservations/cancel/${reservationId}`,
            {
                sendEmail,
                emailMessage,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return {success: true, data: response.data};
    } catch (error) {
        throw error;
    }
}


export async function cancelAdminReservationsAfter(selectedDate, startTime) {
    try {
        const token = getToken();
        const formattedSelectedDate = new Date(selectedDate).toISOString().split('T')[0];
        const startTimeDate = new Date();
        const hours = String(startTimeDate.getHours()).padStart(2, '0');
        const minutes = String(startTimeDate.getMinutes()).padStart(2, '0');
        const formattedStartTime = startTime || `${hours}:${minutes}:00`;

        const response = await axios.patch(
            `${config.apiUrl}/view/reservations/cancel-after?selectedDate=${formattedSelectedDate}&startTime=${formattedStartTime}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return {success: true, data: response.data};
    } catch (error) {
        throw error;
    }
}

function roundToNearestHalfHour(date) {
    const coeff = 1000 * 60 * 30;
    return new Date(Math.round(date.getTime() / coeff) * coeff);
}

export async function blockAdminAllFreeCells(selectedDate, startTime) {
    try {
        const token = getToken();
        const formattedSelectedDate = new Date(selectedDate).toISOString().split('T')[0];
        const now = new Date();
        const startTimeDate = roundToNearestHalfHour(new Date(`${now.toISOString().split("T")[0]}T${startTime}`));
        const hours = String(startTimeDate.getHours()).padStart(2, '0');
        const minutes = String(startTimeDate.getMinutes()).padStart(2, '0');
        const formattedStartTime = `${hours}:${minutes}:00`;

        const response = await axios.post(
            `${config.apiUrl}/view/reservations/block-free-cells?selectedDate=${formattedSelectedDate}&startTime=${formattedStartTime}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return {success: true, data: response.data};
    } catch (error) {
        throw error;
    }
}


export async function sendAdminCancellationEmails(emails, cancelledDate, emailMessage) {
    try {
        const token = getToken();
        cancelledDate = moment(cancelledDate);

        const response = await axios.post(
            `${config.apiUrl}/view/reservations/send-cancellation-emails`,
            emails,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    cancelledDate: cancelledDate.format("YYYY-MM-DD"),
                    emailMessage: emailMessage,
                },
            }
        );
        return {success: true, data: response.data};
    } catch (error) {
        throw error;
    }
}

export async function fetchConfig() {
    try {
        const response = await axios.get(`${config.apiUrl}/config`);
        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function fetchTournaments(status) {
    try {
        const response = await axios.get(`${config.apiUrl}/tournaments`, {
            params: {
                status: status // This will add a query parameter ?status=<status> to the URL
            }
        });
        return {success: true, data: response.data};
    } catch (error) {
        console.error("Error fetching tournaments: ", error);
        throw error;
    }
}

export async function fetchAdminTournaments(status) {
    try {
        const token = getToken();
        const response = await axios.get(`${config.apiUrl}/supervisor/tournaments`, {
            params: {
                status: status,
                Authorization: `Bearer ${token}`,
            }
        });
        return {success: true, data: response.data};
    } catch (error) {
        console.error("Error fetching tournaments: ", error);
        throw error;
    }
}


export async function fetchTournament(tournamentId) {
    try {
        const token = getToken();
        const response = await axios.get(`${config.apiUrl}/supervisor/tournaments/${tournamentId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function fetchParticipants(tournamentId) {
    try {
        const response = await axios.get(`${config.apiUrl}/tournaments/${tournamentId}/participants`);
        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Error fetching tournaments"};
    }
}

export async function addNewOffer(newOffer) {
    try {
        const token = getToken();
        const response = await axios.post(`${config.apiUrl}/offers`, newOffer, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function updateOffer(offerId, offer) {
    try {
        const token = getToken();
        const response = await axios.patch(`${config.apiUrl}/offers/${offerId}`, offer, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function fetchOffers() {
    try {
        const response = await axios.get(`${config.apiUrl}/offers`);
        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function deleteOffer(id) {
    try {
        const token = getToken();
        const response = await axios.delete(`${config.apiUrl}/offers/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}


export async function addTournament(tournamentData) {
    try {
        const token = getToken();
        const response = await axios.post(`${config.apiUrl}/supervisor/tournaments`, tournamentData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function updateTournament(id, tournamentData) {
    try {
        const token = getToken();
        const response = await axios.patch(`${config.apiUrl}/supervisor/tournaments/${id}`, tournamentData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function registerTournamentParticipant(tournamentId, userId, partnerEmail) {
    try {
        const token = getToken();
        const response = await axios.post(`${config.apiUrl}/tournaments/register`, {
            tournamentId: tournamentId,
            userId: userId,
            userEmail: "",
            partnerEmail: partnerEmail
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function registerTournamentParticipantBySupervisor(tournamentId, userEmail, partnerEmail) {
    try {
        const token = getToken();
        const response = await axios.post(`${config.apiUrl}/supervisor/tournaments/register`, {
            tournamentId: tournamentId,
            userId: null,
            userEmail: userEmail,
            partnerEmail: partnerEmail
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function unRegisterTournamentParticipant(Id) {
    try {
        const token = getToken();
        const response = await axios.delete(`${config.apiUrl}/tournaments/register/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function updateConfig(configurationData) {
    try {
        const token = getToken();
        const response = await axios.put(`${config.apiUrl}/super/config`, configurationData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}

export async function deleteTournament(id) {
    try {
        const token = getToken();
        const response = await axios.delete(`${config.apiUrl}/supervisor/tournaments/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response.data || "Unknown error"};
    }
}


export async function startTournament(tournamentId, competitionSystem, seededPlayers) {
    try {
        const token = getToken();
        const requestBody = {
            competitionSystem,
            seededPlayers,
        };

        const response = await axios.post(`${config.apiUrl}/supervisor/tournaments/start/${tournamentId}`, requestBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response ? error.response.data : "Unknown error"};
    }
}

export async function generateFinalMatches(tournamentId) {
    try {
        const response = await axios.post(`${config.apiUrl}/tournaments/generateFinalMatches/${tournamentId}`);
        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response ? error.response.data : "Unknown error"};
    }
}

export async function returnToPhaseGroup(tournamentId) {
    try {
        const response = await axios.delete(`${config.apiUrl}/tournaments/returnToPhaseGroup/${tournamentId}`);
        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response ? error.response.data : "Unknown error"};
    }
}

export async function deleteMatches(tournamentId) {
    try {
        const token = getToken();
        const response = await axios.delete(`${config.apiUrl}/supervisor/tournaments/deleteMatches/${tournamentId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response ? error.response.data : "Unknown error"};
    }
}

export const fetchRoundRobinStandings = async (tournamentId) => {
    try {
        const response = await axios.get(`${config.apiUrl}/tournaments/${tournamentId}/standings`);
        return {success: true, data: response.data};
    } catch (error) {
        console.error('Failed to fetch tournament standings:', error);
        return {success: false, data: error.response ? error.response.data : "Unknown error"};
    }
};

export const fetchMatches = async (tournamentId) => {
    try {
        const response = await axios.get(`${config.apiUrl}/tournaments/${tournamentId}/matches`);
        return {success: true, data: response.data};
    } catch (error) {
        console.error('Failed to fetch matches:', error);
        return {success: false, data: error.response ? error.response.data : "Unknown error"};
    }
}

export async function saveMatchResult(matchResult) {
    try {
        const token = getToken();
        const response = await axios.post(`${config.apiUrl}/tournaments/matches`, matchResult, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, data: error.response ? error.response.data : "Unknown error"};
    }
}

export const fetchEloRanking = async (matchType) => {
    try {
        const response = await fetch(`${config.apiUrl}/elo-ranking?matchType=${matchType}`);
        const data = await response.json();
        if (!Array.isArray(data)) {
            throw new Error('Data is not an array');
        }
        return data;
    } catch (error) {
        console.error("Failed to fetch elo ranking", error);
        throw error;
    }
}

export const fetchEloHistory = async (userId, matchType) => {
    try {
        const response = await axios.get(`${config.apiUrl}/elo-history/${userId}/${matchType}`);
        return {success: true, data: response.data};
    } catch (error) {
        console.error('Failed to fetch elo history:', error);
        return {success: false, data: error.response ? error.response.data : "Unknown error"};
    }
}

export const fetchMatchById = async (matchId) => {
    try {
        const response = await fetch(`${config.apiUrl}/matches/${matchId}`);
        const data = await response.json();
        return { success: response.ok, data };
    } catch (error) {
        console.error("Failed to fetch match by id:", error);
        return { success: false, data: null };
    }
};
