import React from 'react';
import styles from './ScoreRules.module.css';

const ScoreRules = () => {
    return (
        <div className={styles.scoreRulesContainer}>
            <h1>Zasady w turniejach BTT</h1>

            <div className={styles.scoreRulesText}>

                <h5>1. Uczestnicy turniejów BTT </h5>
                <p>W turniejach BTT mogą brać udział osoby zajmujące się grą w tenisa ziemnego w sposób amatorski.
                    Z rozgrywek wykluczeni są gracze z przeszłością zawodniczą, których umiejętności tenisowe wypaczałyby amatorskie współzawodnictwo na korcie.</p>

                <h5>2. Mecze w turniejach jednodniowych</h5>
                <p className={styles.nextRow}>Każdy mecz trwa do dwóch wygranych setów.</p>
                <p className={styles.nextRow}>Pierwsze dwa sety rozgrywane są do 4 wygranych gemów.</p>
                <p className={styles.nextRow}>Przy statnie 3:3 w pierwszym i drugim secie, rozgrywany jest tiebreak do 7
                    pkt, z zachowaniem
                    dwu-punktowej
                    przewagi.</p>

                <p className={styles.nextRow}>Trzecim, rozstrzygającym setem jest tiebreak rozgrywany do 10 pkt, z
                    zachowaniem dwu-punktowej
                    przewagi.</p>

                <p className={styles.nextRow}>Przy stanie równowagi w gemie, rozgrywany jest punkt rozstrzygający, z tą
                    zasadą, że w meczu
                    deblowym stronę podania wybierają odbierający,
                    natomiast w meczu singlowym kierunek serwisu wybiera podający.
                </p>

                <h5>3. Mecze w lidze tenisowej</h5>
                <p>Mecze ligowe prowadzone są do dwóch wygranych setów. Pierwsze dwa sety liczone klasycznie do 6 wygranych gemów.
                Przy statnie 6:6 w secie tiebreak do 7 pkt (z zachowaniem dwupunkowej przewagi). Każdy gem rozgrywany jest na przewagi. Przy stanie 1:1 w setach,
                trzecim dycydującym setem jest tiebreak do 10 pkt (z zachowaniem dwupunktowej przewagi). Gracze, którzy ewidentnie uchylają się od gry,
                    mogą zostać ukarani walkowerem.</p>

                <h5>4. Wprowadzanie wyniku meczu</h5>
                <p>Każdy gracz, zalogowany do systemu, może wprowadzić wynik meczu, w którym brał udział.
                    Natomiast obowiązek zapisania wyniku meczu należy do zawodnika, który wygrał mecz.</p>

                <h5>5. Klasyfikacja w turniejach "każdy z każdym"</h5>
                <p className={styles.nextRow}>Miejsce w tabeli ustalane jest według następujących zasad:</p>
                <p className={styles.nextRow}> <li>Liczba wygranych setów,</li></p>
                <p className={styles.nextRow}> <li>Liczba, będąca różnicą pomiędzy gemami wygranymi i
                    przegranymi. Punkty tiebreaka trzeciego seta przeliczane są proporcjonalnie
                    do liczby gemów w secie, np. jeżeli pierwsze dwa sety toczą się do 4 wygranych gemów, to 10 pkt
                    tiebreka przeliczana jest na 4 pkt. ,</li> </p>
                <p className={styles.nextRow}><li>W przypadku, gdy dwóch graczy posiada równą liczby punktów oraz ten sam
                    wynik, będący różnicą gemów wygranych
                    i przegranych, decyduje bezpośredni wynik meczu pomiędzy tymi graczami.</li></p>

                <h5>6. Potwierdzenie udziału w turnieju jednodniowym</h5>
                <p>Potwierdzeniem udziału osoby zapisanej na listę w turnieju jednodniowym jest jej obecność na
                    kortach BTT w momencie zaplanowanej godziny rozpoczęcia turnieju.
                    W przypadkach losowych istnieje możliwość mailowego lub telefonicznego poinformowania o przybyciu na korty z opóźnieniem.
                    Osoby nieobecne, które nie poinformowały o swoim opóźnieniu będą wykluczane z turnieju. </p>

                <h5>7. Rozstrzyganie sporów</h5>
                <p>Organizatorzy turniejów BTT zastrzegają sobie prawo do rozstrzygania wszelkich sporów, pojawiawiących
                    się na kortach BTT podczas prowadzonych rozgrywek.</p>
            </div>
        </div>
    );
};

export default ScoreRules;