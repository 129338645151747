import React, { useState, useEffect } from 'react';
import {fetchAllPaymentsPaidCount, loadAllPaidPayments} from '../../api/api'; // Assuming you have an API call to fetch paid payments
import styles from '../PaymentOnLine/PaymentOnLine.module.css';
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const PaymentHistory = () => {
    const [payments, setPayments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);



    const fetchPayments = async ()=> {
      try {
          const fetchedPayments = await loadAllPaidPayments(currentPage - 1);
          setPayments(fetchedPayments);
          setIsLoading(false);
      } catch (error){
      console.error('Error while fetching paid payemnts: ', error);
          setError("Błąd pobrania danych");
          setIsLoading(false);
      }

    };


    const fetchPageCount = async () => {
        try {
            const count = await fetchAllPaymentsPaidCount();
            const countAsNumber = Number(count);
            setPageCount(Math.ceil(countAsNumber / 10));
            setHasNextPage(countAsNumber > currentPage * 10);
        } catch (error) {
            console.error('Error while fetching page count:', error);
            setError("Błąd pobrania danych");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPayments();
    }, [currentPage]);

    useEffect(() => {
        fetchPageCount();
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są indeksowane od 0
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    if (isLoading) return <Spinner size={"large"}/>;
    if (error) return <ErrorMessage error={error}/>;

    return (
        <div className={styles.PaymentOnline}>
            <div className={styles.tableContainer}>
                <h5 className={styles.titlePaymentHistory}>Historia płatności</h5>
                {payments.length === 0 ? (
                    <p className={styles.noDataMessage}>Do tej pory nie odnotowano płatności.</p>
                ) : (
                    <table className={styles.paymentsTable}>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Tytuł</th>
                            <th>Kwota</th>
                            <th>Data</th>
                        </tr>
                        </thead>
                        <tbody>
                        {payments.map(({ id, title, amount, updatedAt }) => (
                            <tr key={id}>
                                <td>{id}</td>
                                <td>{title}</td>
                                <td>{amount.toFixed(2)}</td>
                                <td>{formatDate(updatedAt)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
            <div className={styles.pagination}>
                {pageCount > 1 && (
                    <>
                        <button
                            className={styles.prevNextButton}
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Poprzednia
                        </button>
                        <span className={styles.pageNumber}>{currentPage}</span>
                        <button
                            className={styles.prevNextButton}
                            disabled={!hasNextPage}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Następna
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default PaymentHistory;
