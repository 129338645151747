import React from 'react';
import {useState} from "react";
import styles from './MatchResultModal.module.css';
import {formatFirstLetterOfName} from "../../../utils";
import CustomModal from "../../../UI/Modal/CustomModal";
import Spinner from "../../Spinner/Spinner"; // Your custom styles

const initialScores = {
    player1Set1Games: '',
    player1Set2Games: '',
    player1Set3Games: '',
    player1Set1Tiebreak: '',
    player1Set2Tiebreak: '',
    player1Set3Tiebreak: '',
    player2Set1Games: '',
    player2Set2Games: '',
    player2Set3Games: '',
    player2Set1Tiebreak: '',
    player2Set2Tiebreak: '',
    player2Set3Tiebreak: '',
    set1Tiebreak: false,
    set2Tiebreak: false,
    set3Tiebreak: false
};

const mapMatchDataToState = (editingMatch) => {
    const mappedScores = {};

    // Iterate through each set and map the scores and tiebreak information
    editingMatch.setResults.forEach((setResult, index) => {
        const setNumber = index + 1;
        mappedScores[`player1Set${setNumber}Games`] = setResult.playerOneGames.toString();
        mappedScores[`player2Set${setNumber}Games`] = setResult.playerTwoGames.toString();
        mappedScores[`player1Set${setNumber}Tiebreak`] = setResult.playerOneTiebreakPoints ? setResult.playerOneTiebreakPoints.toString() : '';
        mappedScores[`player2Set${setNumber}Tiebreak`] = setResult.playerTwoTiebreakPoints ? setResult.playerTwoTiebreakPoints.toString() : '';
        mappedScores[`set${setNumber}Tiebreak`] = setResult.isTiebreak || false;
    });

    // Map other match properties like walkover and injury resignation
    return {
        ...initialScores, // Start with the initial structure to ensure all fields are present
        ...mappedScores, // Override with the scores from the match
    };
};
const MatchResultModal = ({
                              isOpen,
                              onRequestClose,
                              onSaveResult,
                              editingMatch,
                              matchId,
                              participant1Id,
                              participant2Id,
                              participant1FirstName,
                              participant1LastName,
                              participant2FirstName,
                              participant2LastName,
                              participant1PartnerFirstName,
                              participant1PartnerLastName,
                              participant2PartnerFirstName,
                              participant2PartnerLastName,
                              isThirdSetTiebreak,
                              isUserSupervision,
                              showConfirmButton
                          }) => {
    // Initialize a state with games for each set and optional tiebreaks
    const [scores, setScores] = useState(editingMatch ? mapMatchDataToState(editingMatch) : initialScores);
    const [walkover, setWalkover] = useState(editingMatch?.walkover ?? false);
    const [walkoverWinnerId, setWalkoverWinnerId] = useState(editingMatch?.walkoverWinnerId ?? null);
    const [injuryResignation, setInjuryResignation] = useState(editingMatch?.injuryResignation ?? false);
    const [injuryResignationParticipantId, setInjuryResignationParticipantId] = useState(editingMatch?.injuryResignationParticipantId ?? null);
    const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [clearMatchData, setClearMatchData] = useState(false);
    const [isSaving, setIsSaving] = useState(false);


    const handleWalkoverChange = (event) => {
        const isWalkover = event.target.checked;
        setWalkover(isWalkover);
        setWalkoverWinnerId(isWalkover ? walkoverWinnerId : null)
    };

    const handleInjuryResignationChange = (event) => {
        const isInjuryResignation = event.target.checked;
        setInjuryResignation(isInjuryResignation);
        setInjuryResignationParticipantId(isInjuryResignation ? injuryResignationParticipantId : null)
    };

    const handleWalkoverWinnerChange = (winnerId) => {
        setWalkoverWinnerId(winnerId);
    };

    const handleInjuryResignationParticipantChange = (participantId) => {
        setInjuryResignationParticipantId(participantId)
    };

    const handleScoreChange = (player, set, value) => {
        setScores(prev => ({...prev, [`${player}Set${set}Games`]: value}));
    };


    const handleTiebreakChange = (set, isChecked) => {
        setScores(prev => ({...prev, [`set${set}Tiebreak`]: isChecked}));
    };

    const handleTiebreakScoreChange = (player, set, value) => {
        setScores(prev => ({...prev, [`${player}Set${set}Tiebreak`]: value}));
    };

    const validateMatchData = () => {
        // Check for walkover and injury resignation conditions
        if (walkover) {
            if (!walkoverWinnerId) {
                return 'Wskaż zwyciężcę walkowera.';
            } else {
                // If walkover is selected, other validations are bypassed
                return '';
            }
        }

        if (injuryResignation) {
            if (!injuryResignationParticipantId) {
                return 'Wskaż kontuzjowanego.';
            } else {
                // If injury resignation is selected, other validations are bypassed
                return '';
            }
        }

        // Helper function to check if set scores are valid
        const isValidSet = (player1Games, player2Games) => {
            return (player1Games > 0 || player2Games > 0) && player1Games !== player2Games;
        };

        let player1SetWins = 0;
        let player2SetWins = 0;
        [1, 2, 3].forEach(setNumber => {
            const player1Games = parseInt(scores[`player1Set${setNumber}Games`] || 0, 10);
            const player2Games = parseInt(scores[`player2Set${setNumber}Games`] || 0, 10);

            if (player1Games > player2Games) player1SetWins++;
            if (player2Games > player1Games) player2SetWins++;

            if (!isValidSet(player1Games, player2Games)) {
                return `Nieprawidłowe dane w secie ${setNumber}. Liczba gemów w secie nie może być równa.`;
            }
        });

        // Ensure one player won at least two sets
        if (player1SetWins < 2 && player2SetWins < 2) {
            return 'Gramy do dwóch wygranych setów.';
        }

        // Check for sequential set data validity
        for (let i = 2; i <= 3; i++) {
            const prevSet = i - 1;
            const player1PrevGames = parseInt(scores[`player1Set${prevSet}Games`] || 0, 10);
            const player2PrevGames = parseInt(scores[`player2Set${prevSet}Games`] || 0, 10);
            const player1Games = parseInt(scores[`player1Set${i}Games`] || 0, 10);
            const player2Games = parseInt(scores[`player2Set${i}Games`] || 0, 10);

            if ((player1Games > 0 || player2Games > 0) && (player1PrevGames === 0 && player2PrevGames === 0)) {
                return `Dla seta nr ${i} poprzedni set nie może zawierać samych zer.`;
            }
        }

        // If all validations pass
        return '';
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsSaving(true);

        if (clearMatchData) {
            // Define logic to clear match data
            const clearedScores = {...initialScores}; // Assuming initialScores represent cleared state
            onSaveResult(matchId, clearedScores, false, null, false, null, true); // Add a parameter or modify how you signal that data should be cleared
        } else {
            // Existing validation and save logic
            const validationError = validateMatchData();
            if (validationError) {
                setIsCustomModalOpen(true);
                setModalMessage(validationError);
                setIsSaving(false);
                return;
            }

            // onSaveResult(matchId, scores, walkover, walkoverWinnerId, injuryResignation, injuryResignationParticipantId, false);
            await onSaveResult(matchId, scores, walkover, walkoverWinnerId, injuryResignation, injuryResignationParticipantId, false);
        }
        setIsSaving(false);
    };

    const createSetInputs = (setNumber) => {
        return (
            <div key={setNumber} className={styles.setGroup}>
                <label>{`Set ${setNumber}:`}</label>
                <div className={styles.scoreInputs}>
                    <input
                        type="number"
                        min="0"
                        value={scores[`player1Set${setNumber}Games`]}
                        onChange={(e) => handleScoreChange('player1', setNumber, e.target.value)}
                        className={styles.scoreInput}
                    />
                    <input
                        type="number"
                        min="0"
                        value={scores[`player2Set${setNumber}Games`]}
                        onChange={(e) => handleScoreChange('player2', setNumber, e.target.value)}
                        className={styles.scoreInput}
                    />
                </div>
                {setNumber !== 3 || !isThirdSetTiebreak ? (
                    <div className={styles.tiebreakCheckbox}>
                        <input
                            type="checkbox"
                            checked={scores[`set${setNumber}Tiebreak`]}
                            onChange={(e) => handleTiebreakChange(setNumber, e.target.checked)}
                            id={`tiebreak${setNumber}`}
                        />
                        <label htmlFor={`tiebreak${setNumber}`}>Tiebreak</label>
                    </div>
                ) : null}
                {scores[`set${setNumber}Tiebreak`] && (
                    <div className={styles.tiebreakInputs}>
                        <input
                            type="number"
                            min="0"
                            value={scores[`player1Set${setNumber}Tiebreak`]}
                            onChange={(e) => handleTiebreakScoreChange('player1', setNumber, e.target.value)}
                            className={styles.scoreInput}
                        />
                        <input
                            type="number"
                            min="0"
                            value={scores[`player2Set${setNumber}Tiebreak`]}
                            onChange={(e) => handleTiebreakScoreChange('player2', setNumber, e.target.value)}
                            className={styles.scoreInput}
                        />
                    </div>
                )}
            </div>
        );
    };

    if (!isOpen) {
        return null;
    }

    const handleCloseCustomModal = () => {
        setIsCustomModalOpen(false);
    };

    const formatName = (firstName, lastName, partnerFirstName, partnerLastName) => {
        return (
            <>
                <span>{` ${lastName} ${formatFirstLetterOfName(firstName)}`}</span>
                {partnerFirstName && partnerLastName && (
                    <>
                        <span>{`/ ${partnerLastName} ${formatFirstLetterOfName(partnerFirstName)} `}</span>
                    </>
                )}
            </>
        );
    };


    return (
        <div className={styles.modalOverlay}>
            <CustomModal
                isOpen={isCustomModalOpen}
                onRequestClose={handleCloseCustomModal}
                message={modalMessage}
                isPositiveMessage={modalMessage.includes("Operacja")}
                cancelLabel="Zamknij"
                confirmButtonColor="red"
                cancelButtonColor="green"
            />
            <div className={styles.modal}>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.participantNames}>
                        <h5>{participant1LastName} {formatFirstLetterOfName(participant1FirstName)} <br/>
                            {participant1PartnerLastName} {formatFirstLetterOfName(participant1PartnerFirstName)}
                        </h5>
                        <h5>{participant2LastName} {formatFirstLetterOfName(participant2FirstName)}<br/>
                            {participant2PartnerLastName} {formatFirstLetterOfName(participant2PartnerFirstName)}
                        </h5>
                    </div>
                    {[1, 2, 3].map(createSetInputs)}
                    <div className={styles.checkboxGroup}>
                        <label>
                            <input
                                type="checkbox"
                                checked={walkover}
                                onChange={handleWalkoverChange}
                            />
                            Walkower
                        </label>
                        {walkover && (
                            <div className={styles.radioGroup}>
                                <p className={styles.question}>Kto wygrał ?</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="walkoverWinner"
                                        onChange={() => handleWalkoverWinnerChange(participant1Id)}
                                        checked={walkoverWinnerId === participant1Id}
                                    />
                                    {formatName(participant1FirstName, participant1LastName, participant1PartnerFirstName, participant1PartnerLastName)}
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="walkoverWinner"
                                        onChange={() => handleWalkoverWinnerChange(participant2Id)}
                                        checked={walkoverWinnerId === participant2Id}
                                    />
                                    {formatName(participant2FirstName, participant2LastName, participant2PartnerFirstName, participant2PartnerLastName)}
                                </label>
                            </div>
                        )}
                        <label>
                            <input
                                type="checkbox"
                                checked={injuryResignation}
                                onChange={handleInjuryResignationChange}
                            />
                            Krecz
                        </label>
                        {injuryResignation && (
                            <div className={styles.radioGroup}>
                                <p className={styles.question}>Kto skreczował ?</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="injuryResignationParticipant"
                                        onChange={() => handleInjuryResignationParticipantChange(participant1Id)}
                                        checked={injuryResignationParticipantId === participant1Id}
                                    />
                                    {formatName(participant1FirstName, participant1LastName, participant1PartnerFirstName, participant1PartnerLastName)}
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="injuryResignationParticipant"
                                        onChange={() => handleInjuryResignationParticipantChange(participant2Id)}
                                        checked={injuryResignationParticipantId === participant2Id}
                                    />
                                    {formatName(participant2FirstName, participant2LastName, participant2PartnerFirstName, participant2PartnerLastName)}
                                </label>
                            </div>
                        )}
                        {isUserSupervision &&
                            <label>
                                <input
                                    type="checkbox"
                                    checked={clearMatchData}
                                    onChange={(e) => setClearMatchData(e.target.checked)}
                                />
                                Wyczyść dane meczu
                            </label>
                        }
                    </div>
                    <div className={styles.actions}>
                        {isSaving ? (
                            <Spinner/>
                        ) : (
                            <>
                                <button type="submit" className={styles.saveButton}>Zapisz</button>
                                <button type="button" onClick={onRequestClose} className={styles.backButton}>Anuluj
                                </button>
                            </>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MatchResultModal;