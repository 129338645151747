import React from 'react';
import styles from '../ScoreRules/ScoreRules.module.css';

const EloDescription = () => {
    return (
        <div className={styles.scoreRulesContainer}>
            <div className={styles.scoreRulesText}>

                <h5>Czym jest Elo ? </h5>
                <p>System rankingowy Elo to metoda szacowania względnej siły gracza (lub drużyny) w grze.
                    W tym systemie pokonanie przeciwnika z wyższym rankingiem daje ci więcej punktów niż
                    pokonanie przeciwnika z niższym rankingiem. Jednocześnie twój przeciwnik traci tyle samo punktów,
                    ile ty zdobywasz.</p>

                <h5>Geneza</h5>
                <p>Nazwa pochodzi od Arpada Elo — węgiersko-amerykańskiego szachisty
                    (profesora fizyki), który ją wymyślił.
                    Obecnie najbardziej rozpoznawanym rankingiem Elo posługuje się Międzynarodowa Federacja Szachowa - FIDE.
                    Jednak metoda Elo jest szeroko stosowana w wielu innych grach i dyscyplinach sportowych.
                </p>

                <h5>Elo w Bocheńskim Towarzystwie Tenisowym</h5>
                <p>Każda osoba zakładająca konto na btt.org.pl otrzymuje na start 1000 pkt, zarówno w klasyfikacji siglowej jak i deblowej.
                   Aktualizacja Elo następuje w  momencie wpisywania wyniku meczu oddzielnie w kategoriach turniejów singlowych oraz deblowych.
                    Lista rankingowa prezentowana jest dla graczy, dla których został wpisany przynajmniej jeden wynik meczu w przeciągu ostatnich 365 dni.
                </p>

                <h5>Elo w grze deblowej</h5>
                <p> Ranking Elo w grze deblowej jest zapisywany indywidualnie dla każdego zawodnika,
                    z tym zastrzeżeniem, że Elo pary deblowej branej do wyliczeń to średnia
                    arytmetyczna wartości Elo obu zawodników, np dla graczy z wartościami elo 1100 oraz 1200,
                    elo pary deblowej wynosi 1150.
                </p>
            </div>

        </div>
    );
};

export default EloDescription;