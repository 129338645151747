import styles from "./Input.module.css";

const Input = ({ type, placeholder,name, value, onChange, required }) => {
    return (
        <input
            placeholder={placeholder}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            className={styles.input}
        />
    );
};

export default Input;
