import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {AuthProvider} from "./contexts/AuthContext";
import {ConfigProvider} from "./contexts/ConfigContext";

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <ConfigProvider>
                    <App/>
            </ConfigProvider>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
